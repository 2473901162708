import React from "react";
import { NavLink } from "react-router-dom";

import Dialog from "./dialog";
import Confirmation from "./email-confirmation";
import Dataform from "../img/landing-dataform.jpg";
import useForm from "./useForm";

const Email = () => {
	const stateSchema = {
		name: { value: "", error: "" },
		email: { value: "", error: "" }
	};

	const validationSchema = {
		name: {
			required: true,
			validator: {
				regEx: /^[a-z ,.'-]+$/i,
				error: "Ungültiges Format"
			}
		},
		email: {
			required: true,
			validator: {
				regEx: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				error: "Ungültiges Format"
			}
		}
	};

	const { state, handleOnChange, disable } = useForm(stateSchema, validationSchema);

	const onFormSubmit = (event) => {
		event.preventDefault();
		fetch('https://test.pflegesuite.de/email', {
			method: 'POST',
			mode: 'no-cors',
			body: JSON.stringify({
				recipients: ['support', 'sergey'],
				subject: 'test_subject',
				message: {
					name: state.name.value,
					email: state.email.value
				}
			})
		});
	}

	return (
		<div className="dataform-content">
			<form className="block block-right" onSubmit={onFormSubmit}>
				<p>
					Geben Sie Ihre E-Mail-Adresse an und wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen. Sie
					erhalten Zugang zu einem Testkonto von PflegeSuite, wo Sie alle Funktionen ausprobieren können. Gerne stellen
					wir Ihnen auch eine Führung zur Verfügung.
				</p>

				<ps-input class={state.name.error && "input-error"}>
					<icon className="material-icons icon-input">person</icon>
					<div className="element">
						<input type="text" placeholder="Name" name="name" value={state.name.value} onChange={handleOnChange} />
						<span className="bar" />
					</div>
				</ps-input>
				{state.name.error && <span className="input-error-msg">{state.name.error}</span>}

				<ps-input class={state.email.error && "input-error"}>
					<icon className="material-icons icon-input">email</icon>
					<div className="element">
						<input
							type="text"
							placeholder="Email Adresse"
							name="email"
							value={state.email.value}
							onChange={handleOnChange}
						/>
						<span className="bar" />
					</div>
				</ps-input>
				{state.email.error && <span className="input-error-msg">{state.email.error}</span>}

				<textarea placeholder="Kommentar (optional)" name="message" />

				<div className={disable && "disabled"}>
					<Dialog buttonText="Absenden" buttonIcon="send" headerText="Erfolgreich" headerIcon="done_outline">
						<Confirmation />
					</Dialog>
				</div>
			</form>

			<div className="disclaimer">
				Mit Absenden Ihrer Anfrage erklären Sie sich mit der Verarbeitung Ihrer eingegebenen personenbezogenen Daten zur Beantwortung der Anfrage einverstanden. Weitere Informationen und Hinweise zum Widerruf Ihrer Einwilligung entnehmen Sie bitte der <NavLink exact to="/gdpr">Datenschutzerklärung</NavLink>.
			</div>

			<img className="dataform-img" src={Dataform} alt="" />
		</div>
	);
};

export default Email;
