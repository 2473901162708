import React, { useState } from "react";
import { useTransition, animated } from "react-spring";

const Modal = props => {
	const [isOpen, setOpen] = useState(false);

	const transition = useTransition(isOpen, null, {
		from: { top: "-1rem" },
		enter: { top: "0rem" },
		leave: { opacity: 0 }
	});

	const parent = props;

	return (
		<>
			<button onClick={() => setOpen(true)}>
				<icon className="material-icons icon">{parent.buttonIcon}</icon>
				{parent.buttonText}
			</button>
			{isOpen && (
				<div className="overlay-form">
					{transition.map(
						({ item, key, props }) =>
							item && (
								<animated.div key={key} style={props} className="dataform">
									<div className="dataform-header">
										<icon className="material-icons icon-dlg">{parent.headerIcon}</icon>
										<span>{parent.headerText}</span>
									</div>
									{parent.children}
									<div onClick={() => setOpen(false)} className="close">
										<icon className="material-icons">close</icon>
									</div>
								</animated.div>
							)
					)}
				</div>
			)}
		</>
	);
};

export default Modal;
