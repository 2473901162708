import React, {useState} from 'react';
import axios from 'axios';

import useStateWithLocalStorage from "../localStorage";
import { ReactComponent as LogoWhite } from "../img/logo-inline-white.svg";

const ATTEMPTS_LIMIT = 5;
const UNLOCK_AFTER = 60000;

const currentTime = () => (new Date()).getTime();

const axiosInstance = axios.create({baseURL: process.env.BASE_URL});


const LoginForm = () => {

    const [count, setCount] = useState(0);
    setTimeout(() => setCount(count + 1), 1000);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState();
    const [isVisible, setVisible] = useState();

    const [loginAttempts, setLoginAttempts] =
        useStateWithLocalStorage('loginAttempts', 0);

    const [loginLockTime, setLoginLockTime] =
        useStateWithLocalStorage('loginLockTime', 0);


    const getLoginAttempts = () => parseInt(loginAttempts);


    const lockTimeLeft = () => {
        const timeLeft = UNLOCK_AFTER - (currentTime() - loginLockTime);
        return timeLeft > 0 && (timeLeft / 1000).toFixed(0);
    };


    const isLocked = () => getLoginAttempts() >= ATTEMPTS_LIMIT && lockTimeLeft();


    const onLoginSuccess = () => {
        setLoading(false);
        setLoginAttempts(0);
        setError(false);
        window.location = '/static/index.html';
    };


    const onLoginFailed = () => {
        setLoading(false);
        setLoginAttempts(lockTimeLeft() ? getLoginAttempts() + 1 : 1);
        setLoginLockTime(currentTime());
        setError(true);
    };


    const onSubmit = () => {
        setLoading(true);
        axiosInstance.post(
            '/login',
            {username: username, password: password}
        )
            .then(onLoginSuccess)
            .catch(onLoginFailed);
    };


    const loginButton = () =>
        <button onClick={onSubmit} disabled={isLoading}>
            <icon className="material-icons icon">lock_open</icon>
            Anmelden
        </button>;


    const errorMessage = () =>
        <div>
            Benutzername und Passwort stimmen nicht überein.
            <br/>
            Versuche: {getLoginAttempts()}/{ATTEMPTS_LIMIT}
        </div>;


    const lockMessage = timeLeft =>
        <div className="msg-lock">
            Zu viele fehlerhafte Anmeldeversuche.
            <br/>
            Bitte warten Sie noch {timeLeft} Sekunden.
        </div>;


    return (
        <>
            <div className="login-box-header">
                <div className="wavebg bg1">
                    <div className="wave wave1"></div>
                </div>
                <div className="wavebg bg2">
                    <div className="wave wave2"></div>
                </div>
                <div className="wavebg bg3">
                    <div className="wave wave3"></div>
                </div>
                <LogoWhite title="PflegeSuite Logo"/>
            </div>
            <div className="dataform-content">
                <form className="block block-right"
                      onSubmit={e => e.preventDefault()}>
                    <ps-input class={isError && "input-error"}>
                        <icon className="material-icons icon-input">person</icon>
                        <div className="element">
                            <input
                                disabled={isLocked()}
                                type="text"
                                placeholder="Benutzername"
                                value={username}
                                onChange={event => setUsername(event.target.value)}/>
                            <span className="bar" />
                        </div>
                    </ps-input>

                    <ps-input class={isError && "input-error"}>
                        <icon className="material-icons icon-input">lock</icon>
                        <div className="element">
                            <input
                                disabled={isLocked()}
                                type={isVisible ? "text" : "password"}
                                placeholder="Passwort"
                                value={password}
                                onChange={event => setPassword(event.target.value)}/>
                            <span className="bar" />
                        </div>
                        <icon className={`material-icons visibility ${isVisible && "inactive"}`} onClick={() => setVisible(true)}>visibility</icon>
                        <icon className={`material-icons visibility ${!isVisible && "inactive"}`} onClick={() => setVisible(false)}>visibility_off</icon>
                    </ps-input>

                    {isError && getLoginAttempts() < ATTEMPTS_LIMIT && errorMessage()}

                    {isLocked() ? lockMessage(lockTimeLeft()) : loginButton()}

                </form>
            </div>
        </>
    );

};

export default LoginForm;