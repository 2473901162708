import React from "react";
import { NavLink } from "react-router-dom";

import Modal from "./modal";
import LoginForm from "./login-form";

import { ReactComponent as Logo } from "../img/logo-inline.svg";

const Header = () => {
	return (
		<header>
			<div className="slider">
				<NavLink className="logo" exact to="/">
					<Logo title="PflegeSuite Logo" />
				</NavLink>

				<div className="menu">
					<NavLink exact to="functions" activeClassName="menu-item-active">
						<div className="menu-item purple">
							Funktionen
							<div className="bar" />
						</div>
					</NavLink>
					<NavLink exact to="tutorials" activeClassName="menu-item-active">
						<div className="menu-item teal">
							Anleitungen
							<div className="bar" />
						</div>
					</NavLink>
					<NavLink exact to="support" activeClassName="menu-item-active">
						<div className="menu-item green">
							Support & FAQ
							<div className="bar" />
						</div>
					</NavLink>
				</div>

				<div className="login-box grey">
					<Modal
						buttonText="Kunden Login"
						buttonIcon="lock_open">
						<LoginForm/>
					</Modal>
				</div>
			</div>
			<span />
		</header>
	);
};

export default Header;
