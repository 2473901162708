import React from "react";

const LandingGDPR = () => {
	return (
		<main>
			<h1>Datenschutzerklärung</h1>

			<div className="container green">
				<div className="card card-wide">
					<icon className="material-icons">lock</icon>

					<div className="card-text">
						<h3>Allgemeines</h3>

						<p>
						Die Einhaltung der Datenschutzgesetze ist für PflegeSuite, Inhaber ZAD Tech GmbH, nicht nur 	gesetzliche Pflicht, sondern auch ein wichtiger Vertrauensfaktor. Mit den nachfolgenden Datenschutzbestimmungen möchten wir Sie deshalb transparent über Art, Umfang und Zweck der von Ihnen erhobenen und verarbeiteten personenbezogenen Daten innerhalb dieses Internetauftritts sowie Ihre Rechte informieren.
						</p>

						<h3>Verantwortlichkeit für die Datenverarbeitung</h3>

						<p>
						Die PflegeSuite, Inhaber ZAD Tech GmbH, Ottilienstraße 10, 37154 Northeim (Im Folgenden: „Wir“) ist als Betreiber der Webseite www.pflegesuite.de, Verantwortlicher gem. Art. 4 Nr. 7 der EU-Datenschutz-Grundverordnung (DSGVO). Bei Fragen können Sie sich an team@pflegesuite.de wenden.
						</p>

						<h3>Betroffenenrechte</h3>

						<p><b>Ihre Rechte als betroffene Person</b></p>

						<p>
						Als betroffene Person haben Sie uns gegenüber folgende Rechte hinsichtlich Ihrer personenbezogenen Daten. Sie haben:
						</p>

						<ul>
							<li>
							Ein Recht auf Auskunft unter anderem über die Kategorien der verarbeiteten Daten, der Verarbeitungszwecke, die Speicherdauer sowie etwaige Empfänger, gemäß Art. 15 DSGVO und § 34 BDSG.
							</li>
							<li>
							Ein Recht auf Berichtigung oder Löschung unrichtiger bzw. unvollständiger Daten, gemäß Art. 16 und 17 DSGVO und § 35 BDSG.
							</li>
							<li>
							Unter den Voraussetzungen des Art. 18 DSGVO oder § 35 Abs. 1 S. 2 BDSG ein Recht auf Einschränkung der Verarbeitung.
							</li>
							<li>
							Ein Recht auf Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs.1 DSGVO, soweit die Datenverarbeitung aufgrund eines berechtigten Interesses erfolgte.
							</li>
							<li>
							Ein Recht auf Widerruf einer abgegebenen Einwilligung mit Wirkung für die Zukunft gemäß Art. 7 Abs. 3 DSGVO.
							</li>
							<li>
							Ein Recht auf Datenübertragbarkeit in einem gängigen Format gemäß Art. 20 DSGVO.
							</li>
							<li>
							Sie haben gemäß Art. 22 DSGVO ein Recht darauf, nicht einer ausschließlich auf einer automatisierten Verarbeitung beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies umfasst auch Profiling im Sinne des Art. 4 Nr. 4 DSGVO.
							</li>
							<li>
							Sie haben zudem das Recht, sich gemäß Art. 77 DSGVO bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren, insbesondere in dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes.
							</li>
						</ul>

						<br />

						<p><b>Verfahren</b></p>

						<p>
						Wenn Sie Ihre Rechte nach der DSGVO und dem BDSG uns gegenüber geltend machen, werden wir die von Ihnen dabei an uns übermittelten Daten verarbeiten, um Ihren Anspruch zu erfüllen.
						</p>

						<p>
						Im Anschluss speichern wir die von Ihnen an uns übermittelten Daten und die von uns an Sie im Gegenzug übermittelten Daten zum Zwecke der Dokumentation bis zum Ablauf der ordnungswidrigkeitenrechtlichen erjährungsfrist (3 Jahre).
						</p>

						<p>
						Die Rechtsgrundlage für die Verarbeitung und Speicherung der Daten ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung). Das berechtigte Interesse ergibt sich aus unserer Verpflichtung Ihrem Anliegen nachzukommen und dem Bedarf, uns in einem möglichen Bußgeldverfahren entlasten zu können, indem wir nachweisen, dass wir Ihrem Anliegen ordnungsgemäß nachgekommen sind.
						</p>

						<p>
						Der Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten. Wir weisen aber darauf hin, dass die Verarbeitung Ihrer Daten zum Nachweis der Einhaltung der Betroffenenrechte zwingend im Sinne des Art. 21 Abs. 1 DSGVO ist, da andere Nachweismöglichkeiten nicht bestehen bzw. nicht gleichermaßen geeignet sind.
						</p>

						<h3>Datenschutzmaßnahmen</h3>

						<p>
						Wir sichern unsere Webseite und sonstigen Systeme – und damit auch Ihre Daten – durch technische und organisatorische Maßnahmen gegen Verlust, Zerstörung, Zugriff, Veränderung oder Verbreitung durch unbefugte Personen ab. Insbesondere werden Ihre persönlichen Daten verschlüsselt durch das Internet übertragen. Wir bedienen uns dabei des Codierungssystems TLS (Transport Layer Security).
						</p>

						<p>
						Jedoch ist die Übertragung von Informationen über das Internet nie vollständig sicher, weshalb wir die Sicherheit der von unserer Webseite übermittelten Daten nicht zu 100% garantieren können.
						</p>

						<h3>Modalitäten der Datenverarbeitung</h3>

						<p><b>Quellen und Kategorien von personenbezogenen Daten</b></p>

						<p>Wir verarbeiten Ihre personenbezogenen Daten, soweit sie für die Begründung, inhaltliche Ausgestaltung oder Änderung eines Vertragsverhältnisses zwischen uns und Ihnen erforderlich sind (Bestandsdaten). Bestandsdaten können insbesondere sein: Name, Anrede, Kontaktdaten (Postadresse, Telefon, E-Mail-Adresse), Geburtsdatum usw.
						</p>

						<p>
						Ferner verarbeiten wir Ihre Nutzungsdaten. Nutzungsdaten sind Daten, die durch Ihr Verhalten bei der Nutzung unseres Webangebots und unserer Dienste anfallen, insbesondere Ihre IP-Adresse, Beginn und Ende Ihres Besuchs auf unserer Webseite und Informationen darüber, welche Inhalte Sie auf unserer Webseite abgerufen haben.
						</p>

						<p>
						Die genannten Daten erheben wir entweder direkt bei Ihnen (z.B. durch den Besuch der Webseite) 	oder, soweit dies nach den Datenschutzgesetzen zulässig ist, bei Dritten bzw. aus öffentlich 	zugänglichen Quellen (z.B. Handels- und Vereinsregister, Presse, Medien, Internet).
						</p>

						<p><b>Datenübermittlung an Drittstaaten außerhalb der EU</b></p>

						<p>
						Alle Informationen, die wir von Ihnen oder über Sie erhalten, werden grundsätzlich auf Servern innerhalb der Europäischen Union verarbeitet. Eine Übermittlung Ihrer Daten an oder eine Verarbeitung Ihrer Daten in Drittstaaten erfolgt ohne Ihre ausdrückliche Einwilligung lediglich, sofern dies gesetzlich vorgesehen oder gestattet ist und in dem Drittstaat ein hierfür angemessenes Datenschutzniveau sichergestellt ist.
						</p>

						<p><b>Weitergabe von Daten, Auftragsverarbeitung</b></p>

						<p>
						Wir geben Ihre personenbezogenen Daten niemals unberechtigt an Dritte weiter. Wir können Ihre Daten aber insbesondere an Dritte weitergeben, wenn Sie in die Datenweitergabe eingewilligt haben, wenn die Weitergabe zur Erfüllung unserer rechtlichen Verpflichtungen notwendig ist oder wenn wir aufgrund gesetzlicher Bestimmungen oder behördlicher oder gerichtlicher Anordnungen zu einer Datenweitergabe berechtigt oder verpflichtet sind. Dabei kann es sich insbesondere um die Auskunftserteilung für Zwecke der Strafverfolgung, zur Gefahrenabwehr oder zur Durchsetzung geistiger Eigentumsrechte handeln.
						</p>

						<p>
						Unter Umständen übermitteln wir Ihre Daten außerdem an externe Dienstleister, die Daten in unserem Auftrag und nach unserer Weisung verarbeiten (Auftragsverarbeiter), um unsere eigene Datenverarbeitung zu vereinfachen oder zu entlasten. Jeder Auftragsverarbeiter wird durch einen Vertrag nach Maßgabe des Art. 28 DSGVO verpflichtet. Das heißt insbesondere, dass der Auftragsverarbeiter hinreichend Garantien dafür zu bieten hat, dass durch ihn geeignete technische und organisatorische Maßnahmen so durchgeführt werden, dass die Verarbeitung im Einklang mit den Anforderungen der DSGVO erfolgt und der Schutz Ihrer Rechte als betroffene Person gewährleistet ist. Trotz Beauftragung von Auftragsverarbeitern bleiben wir für die Verarbeitung Ihrer personenbezogenen Daten die verantwortliche Stelle im Sinne der Datenschutzgesetze.
						</p>

						<p><b>Zweck der Datenverarbeitung</b></p>

						<p>
						Wir nutzen die Daten grundsätzlich nur zu dem Zweck, zu dem die Daten von Ihnen erhoben wurden. Wir können die Daten zu einem anderen Zweck weiterverarbeiten, wenn nicht dieser andere Zweck mit dem ursprünglichen Zweck unvereinbar ist (Art. 5 Abs. 1 Lit. c) DSGVO).
						</p>

						<p><b>Speicherdauer</b></p>

						<p>
						Soweit im Einzelnen nichts anderes angegeben ist, speichern wir von Ihnen erhobene Daten nur so lange, wie es für den jeweiligen Zweck erforderlich ist, es sei denn es stehen gesetzliche Aufbewahrungspflichten der Löschung entgegen, z.B. aus dem Handelsrecht oder dem Steuerrecht.
						</p>

						<h3>Einzelne Verarbeitungstätigkeiten</h3>

						<p>
						Wir möchten Ihnen im Folgenden so transparent wie möglich darstellen, welche Daten wir von Ihnen zu welcher Gelegenheit, auf welcher Grundlage und zu welchem Zweck verarbeiten.
						</p>

						<p><b>Server-Log-Dateien</b></p>

						<p>
						Bei jedem Aufruf einer Webseite und bei jedem Abruf von Daten von einem Server werden allgemeine Informationen automatisch an den bereitstellenden Server übermittelt. Diese Datenübermittlung erfolgt automatisch und ist ein grundlegender Bestandteil der Kommunikation zwischen Geräten im Internet.
						</p>

						<p>
						Die standardmäßig übertragenen Daten umfassen unter anderem folgende Informationen: Ihre IP- Adresse, Produkt- und Versionsinformationen über den verwendeten Browser und das Betriebssystem (sog. user agent), die Internetseite von welcher Ihr Zugriff stattfand (sog. referer), Datum und Uhrzeit der Anfrage (sog. timestamp). Außerdem werden der http-Status und die übertragene Datenmenge im Rahmen dieser Anfrage erfasst.
						</p>

						<p>
						Diese Informationen werden vom Server protokolliert, in einer Tabelle abgelegt und dort kurzfristig gespeichert (sog. Server-Log-Dateien). Durch die Analyse dieser Log-Dateien können wir Fehler der Webseite feststellen und anschließend beseitigen, die Auslastung der Webseite zu bestimmten Zeiten feststellen und darauf aufbauend Anpassungen oder Verbesserungen vornehmen sowie die Sicherheit des Servers gewährleisten, indem wir nachvollziehen können, von welcher IP-Adresse Angriffe auf unseren Server ausgeführt wurden.
						</p>

						<p>
						Ihre IP-Adresse wird nur für die Zeit Ihrer Nutzung der Webseite gespeichert und im Anschluss daran unverzüglich gelöscht oder durch Kürzung teilweise unkenntlich gemacht. Die übrigen Daten werden für eine begrenzte Zeitdauer (meist 7 Tage) gespeichert.
						</p>

						<p>
						Die Rechtsgrundlage für die Nutzung der Server-Log-Dateien ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung). Das berechtigte Interesse ergibt sich aus der Notwendigkeit für den Betrieb und die Wartung unserer Webseite, wie wir vorstehend erläutert haben. Der Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten. Wir weisen aber bereits im Vorfeld darauf hin, dass die Verarbeitung Ihrer Daten in Server-Log-Dateien zwingend im Sinne des Art. 21 Abs. 1 DSGVO ist, da die Webseite ansonsten überhaupt nicht betrieben werden kann.
						</p>

						<p><b>Cookies</b></p>

						<p>
						Wir benutzen zur Verbesserung der Nutzerfreundlichkeit auf unserer Webseite sogenannte „Cookies“.
						</p>

						<p>
						<i>Was Cookies sind</i>
						<br />
						Sehr vereinfacht gesagt ist ein Cookie eine kleine Textdatei, die Daten über besuchte Webseiten speichert. Cookies können in vielfacher Weise eingesetzt werden. Sie können zum Beispiel eine Art „Benutzerprofil“ speichern, also Dinge wie Ihre bevorzugte Sprache und andere Seiteneinstellungen, die von unserer Webseite benötigt werden, um Ihnen bestimmte Dienste anbieten zu können. Die Cookie-Datei wird auf Ihrem Endgerät gespeichert und kann auch dabei helfen, Sie bei einem erneuten Aufruf unserer Webseite wiederzuerkennen.
						</p>

						<p>
						Unter Umständen können wir durch die Cookies außerdem Informationen über Ihre bevorzugten Aktivitäten auf unserer Webseite erhalten und unsere Webseite so an Ihren individuellen Interessen ausrichten oder sogar die Geschwindigkeit der Navigation auf unserer Webseite erhöhen.
						</p>

						<p>
						<i>Wie Sie Cookies vermeiden können</i>
						<br />
						Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit manuell löschen.
						</p>

						<p>Sie können die Speicherung von Cookies aber auch durch eine entsprechende Einstellung Ihres Browsers von vornherein verhindern. Bitte beachten Sie aber, dass Sie dann möglicherweise nicht sämtliche Funktionen unserer Webseite vollumfänglich nutzen können oder dass es gegebenenfalls zu Fehlern in der Darstellung und Nutzung der Webseite kommen kann.</p>

						<p>
						<i>Welche Cookies eingesetzt werden</i>
						<br />
						Im Einzelnen setzt unsere Webseite folgende Cookies:
						</p>

						<ul>
							<li>
								Name: _ga
								<br />
								Erläuterung: Dieses Cookie wird vom eingesetzten Dienst Google Analytics benötigt. Es dient dazu, einzelne Webseitenbesucher wiederzuerkennen, indem es eine Kennung aus zwei auf Zufallsbasis erzeugten 32-Bit-Zahlen (z. B. 12345.67890) verwendet. Das Cookie verliert seine Gültigkeit nach 2 Jahren. Auch der Anbieter des Dienstes kann auf die in dem Cookie gespeicherten Informationen zugreifen. Hinweise dazu, wie Sie die Speicherung dieses Cookies verhindern können,  finden Sie unten bei "Google Analytics".
								<br />
								Herkunft (Domain): .pflegesuite.de
								<br />
								Gültigkeit/Speicherdauer: 2 Jahre
								<br />
								Zugriff Dritter: Ja, Google
							</li>
							<li>
								Name: _gid
								<br />
								Erläuterung: Dieses Cookie wird vom eingesetzten Dienst Google Analytics benötigt. Es dient dazu, einzelne Webseitenbesucher wiederzuerkennen, indem es eine Kennung aus zwei auf Zufallsbasis erzeugten 32-Bit-Zahlen (z. B. 12345.67890) verwendet. Das Cookie verliert seine Gültigkeit nach 1 Tag. Auch der Anbieter des Dienstes kann auf die in dem Cookie gespeicherten Informationen zugreifen. Hinweise dazu, wie Sie die Speicherung dieses Cookies verhindern können,  finden Sie unten bei "Google Analytics".
								<br />
								Herkunft (Domain): .pflegesuite.de
								<br />
								Gültigkeit/Speicherdauer: 1 Tag
								<br />
								Zugriff Dritter: Ja, Google
							</li>
							<li>
								Name: _gat_gtag_UA
								<br />
								Erläuterung: Dieses Cookie wird vom eingesetzten Dienst Google Analytics benötigt. Es dient dazu, einzelne Webseitenbesucher wiederzuerkennen, indem es eine Kennung aus zwei auf Zufallsbasis erzeugten 32-Bit-Zahlen (z. B. 12345.67890) verwendet. Das Cookie verliert seine Gültigkeit nach 1 Minute. Auch der Anbieter des Dienstes kann auf die in dem Cookie gespeicherten Informationen zugreifen. Hinweise dazu, wie Sie die Speicherung dieses Cookies verhindern können,  finden Sie unten bei "Google Analytics".
								<br />
								Herkunft (Domain): .pflegesuite.de
								<br />
								Gültigkeit/Speicherdauer: 1 Minute
								<br />
								Zugriff Dritter: Ja, Google
							</li>
							<li>
								Name: csrftoken
								<br />
								Erläuterung: Dieses Cookie dient der Sicherheit der Webseite und dem Schutz des Nutzers gegen CSRF- (Cross- Site-Request-Forgery) Attacken, in dem er jede Anfrage vom Client an den Server mit einem eindeutigen „Token“ versieht, der sicher stellt, dass der Request vom Client kommt.
								<br />
								Herkunft (Domain): .pflegesuite.de
								<br />
								Gültigkeit/Speicherdauer: 1 Jahr
								<br />
								Zugriff Dritter: Nein
							</li>
						</ul>

						<br />

						<p>
						<i>Rechtsgrundlage</i>
						<br />
						Die Rechtsgrundlage für die Nutzung von Cookies, die für die Funktion der Webseite zwingend erforderlich sind (z.B. Warenkorb-Cookie, Session-Cookie) ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung). Das berechtigte Interesse ergibt sich aus unserem Bedarf Ihnen eine funktionierende Webseite anbieten zu können. Cookies sind dazu erforderlich, weil sie ein integraler Bestandteil der aktuellen Internet-Technologie sind und ohne Cookies viele Funktionen aktueller Webseiten nicht zur Verfügung stünden. Wir benötigen daher Cookies, um Ihnen die Webseite auf Ihre Anfrage zur Verfügung stellen zu können.
						</p>

						<p>
						Der Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten.
						</p>

						<p>
						Wir weisen aber darauf hin, dass die Verarbeitung Ihrer Daten in bestimmten Cookies zwingend im Sinne des Art. 21 Abs. 1 DSGVO ist, da die Webseite ansonsten überhaupt nicht betrieben werden kann und wir technisch nicht die Möglichkeit haben, das Setzen von Cookies auf bestimmten individuellen Endgeräten zu unterbinden. Sie können das in Ihrem Browser aber möglicherweise selbst tun. Werfen Sie für weitere Informationen hierzu bitte einen Blick in die Anleitung Ihres Browsers.
						</p>

						<p>Die Rechtsgrundlage für die Nutzung von Cookies, die für die Funktion der Webseite nicht zwingend erforderlich sind, ist Art. 6 Abs. 1 S. 1 Buchstabe a) DSGVO (Einwilligung des Betroffenen). Wir bitten Sie beim ersten Aufruf der Webseite über einen eingeblendeten Hinweistext um Ihr Einverständnis zur Verwendung von nicht notwendigen Cookies. Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie in Ihrem Browser alle Cookies löschen. Wie dies in Ihrem Browser funktioniert, können Sie der Anleitung des Browsers entnehmen.</p>

						<p><b>Web Storage</b></p>

						<p>
						Wir benutzen zur Verbesserung der Nutzerfreundlichkeit auf unserer Webseite den „Web Storage“ Ihres Browsers.
						</p>

						<p>
						<i>Was ist der Web Storage</i>
						<br />
						Der Web Storage ist eine Technik für Webanwendungen, mit der Daten in einem Webbrowser gespeichert werden. Der Web Storage kann vereinfacht als eine Weiterentwicklung von Cookies angesehen werden, unterscheidet sich von diesen jedoch in einigen Punkten.
						</p>

						<p>
						Im Gegensatz zu Cookies, auf die sowohl Server als auch Client zugreifen können, wird der Web Storage vollständig vom Client gesteuert. Das heißt, es werden hierbei nicht mit jedem Aufruf der Webseite Daten zum Server übertragen. Der Zugriff erfolgt ausschließlich lokal über Skripte auf der Webseite. Konkret bedeutet das, dass ein Zugriff Dritter auf die gespeicherten Informationen über die Webseite ausgeschlossen ist. Nur Sie und wir können auf die lokal gespeicherten Daten zugreifen.
						</p>

						<p>
						<i>Rechtsgrundlage</i>
						<br />
						Die Rechtsgrundlage für die Nutzung des Web Storage, die für die Funktion der Webseite zwingend erforderlich ist, ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung). Das berechtigte Interesse ergibt sich aus unserem Bedarf Ihnen eine funktionierende Webseite anbieten zu können. Der Web Storage ist dazu erforderlich, weil er ein integraler Bestandteil der aktuellen Internet-Technologie ist und ohne ihn viele Funktionen aktueller Webseiten nicht zur Verfügung stünden. Wir benötigen daher den Web Storage, um Ihnen die Webseite auf Ihre Anfrage zur Verfügung stellen zu können.
						</p>

						<p>
						Der Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten.
						</p>

						<p>
						Wir weisen aber darauf hin, dass die Verarbeitung Ihrer Daten im Web Storage unter Umständen zwingend im Sinne des Art. 21 Abs. 1 DSGVO ist, da die Webseite ansonsten überhaupt nicht betrieben werden kann und wir technisch nicht die Möglichkeit haben, die Nutzung auf bestimmten individuellen Endgeräten zu unterbinden. Sie können das in Ihrem Browser aber möglicherweise selbst tun. Werfen Sie für weitere Informationen hierzu bitte einen Blick in die Anleitung Ihres Browsers.
						</p>

						<p>
						Die Rechtsgrundlage für die Nutzung des Web Storage, die für die Funktion der Webseite nicht zwingend erforderlich ist, ist Art. 6 Abs. 1 S. 1 Buchstabe a) DSGVO (Einwilligung des Betroffenen). Wir bitten Sie beim ersten Aufruf der Webseite über einen eingeblendeten Hinweistext um Ihr Einverständnis zur Verwendung. Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie in Ihrem Browser alle Cookies löschen. Wie dies in Ihrem Browser funktioniert, können Sie der Anleitung des Browsers entnehmen.
						</p>

						<p><b>Kontaktaufnahme</b></p>

						<p>
						Unsere Webseite bietet Möglichkeiten an, direkt mit uns in Kontakt zu treten, damit wir Ihnen ein individuelles Angebot zukommen lassen können. Durch die Kontaktaufnahme erklären Sie sich mit der Verarbeitung und Speicherung Ihrer übermittelten Daten (insbesondere Ihrer E-Mailadresse) zur Bearbeitung Ihres Anliegens einverstanden. Dieser Verarbeitung können Sie jederzeit mit Wirkung für die Zukunft widersprechen. Nutzen Sie hierfür bitte unsere Kontaktdaten im Impressum. Beachten Sie aber, dass wir Ihr Anliegen dann nicht mehr bearbeiten werden.
						</p>

						<p>
						Zu der Angabe von besonders sensiblen personenbezogenen Daten im Sinne des Art. 9 DSGVO (z.B. Gesundheitsdaten) weisen wir darauf hin, dass bei der Übermittlung solcher Daten über das Internet Risiken bestehen, insbesondere durch das Abfangen/Ausspähen der Daten. Falls Sie sich diesbezüglich unsicher sind, raten wir Ihnen, uns postalisch oder telefonisch zu kontaktieren.
						</p>

						<p>
						Die von Ihnen an uns übermittelten Daten verarbeiten wir ausschließlich bis zur Erreichung des jeweiligen Zwecks Ihrer Kontaktaufnahme, es sei denn, dass gesetzliche Aufbewahrungsfristen entgegenstehen. Wenn Zweck Ihrer Kontaktaufnahme die Geltendmachung von Betroffenenrechten ist, gilt das im Abschnitt „Ihre Rechte als betroffene Person“ Gesagte.
						</p>

						<p>Folgende Angaben benötigen wir von Ihnen zwingend:</p>

						<ul>
							<li>
							Name: Die Angabe Ihres Namens ist für uns notwendig, weil wir im Rahmen einer professionellen Korrespondenz auf eine persönliche Ansprache Wert legen. Ihre Angabe verwenden wir ausschließlich zu diesem Zweck.
							</li>
							<li>
							Email-Adresse: Die Angabe Ihrer Email-Adresse ist für uns notwendig, weil wir nur so Ihre Anfrage beantworten können. Ihre Angabe verwenden wir ausschließlich zu diesem Zweck.
							</li>
						</ul>

						<br />

						<p>
						Die Rechtsgrundlage für die Nutzung der von Ihnen durch die Kontaktaufnahme an uns übermittelten Daten ist Art. 6 Abs. 1 S. 1 Buchstabe a) DSGVO (Einwilligung der betroffenen Person). Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten.
						</p>

						<p>
						Die Rechtsgrundlage für die Verarbeitung der Pflichtangaben ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung). Das berechtigte Interesse haben wir Ihnen in der obenstehenden Liste dargelegt.
						</p>

						<p>
						Der Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten.
						</p>

						<p><b>Kundenlogin</b></p>

						<p>
						Unsere Webseite bietet Ihnen die Möglichkeit, sich für weitere Funktionen anzumelden. Eine Anmeldung ist ausschließlich registrierten Kunden möglich.
						</p>

						<p>
						Rechtsgrundlage für die von Ihnen im Anmeldungsprozess angegebenen Daten ist Art. 6 Abs. 1 S. 1 Buchstabe b) DSGVO.
						</p>

						<h3>Dienste Dritter</h3>

						<p>
						Wir nutzen, zur Vereinfachung unserer Datenverarbeitung und um den Funktionsumfang unserer Webseite zu erweitern, Dienstleistungen/Ressourcen von Dritten, beispielsweise Plugins, externe Inhalte, Software oder sonstige externe Dienstleister (Dienste). Dabei werden möglicherweise auch personenbezogene Daten an den Diensteanbieter übermittelt. Um Ihre Daten zu schützen, haben wir die Diensteanbieter erforderlichenfalls gem. Art. 28 DSGVO vertraglich verpflichtet, Ihre Daten nur nach unserer Anweisung zu verarbeiten.
						</p>

						<p>
						Wir weisen ausdrücklich darauf hin, dass wir regelmäßig nur für die Datenerhebung und Übermittlung durch den Dienst verantwortlich im Sinne der DSGVO sind, nicht aber für eine eventuell später erfolgende Verarbeitung durch den jeweiligen Diensteanbieter.
						</p>

						<p>
						Im Einzelnen nutzen wir folgende Dienste:
						</p>

						<p><b>Google-Dienste</b></p>

						<p>
						Unsere Webseite verwendet folgende Dienste der Firma Google Ireland Limited („Google EU“), Gordon House, Barrow Street, Dublin 4, Irland. Diese Firma vertritt in der EU die Firma Google LLC („Google US&quot;), 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Die Firma Google US erfüllt die Anforderungen des „EU-US-Privacy-Shield“ (zu deutsch: EU-US-Datenschutzschild). Das Privacy-Shield-Abkommen regelt den Schutz personenbezogener Daten, die aus einem Mitgliedsstaat der Europäischen Union in die USA übertragen werden. Es stellt sicher, dass die übertragenen Daten dort ebenfalls einem der Europäischen Union vergleichbaren Datenschutzniveau unterliegen. Die Liste zertifizierter Unternehmen können Sie hier abrufen: <a href="https://www.privacyshield.gov/list">https://www.privacyshield.gov/list</a>.
						</p>

						<p>
						Durch die Nutzung der Dienste werden Daten an Google EU und unter Umständen von Google EU an Google US übermittelt. Der Google-Konzern kann die übermittelten Daten verarbeiten, um anonymisierte Nutzerprofile für statistische Zwecke zu erstellen. Wenn Sie darüber hinaus über ein Google-Konto verfügen und in diesem eingeloggt sind, kann Google die übermittelten Daten Ihrem Konto zuordnen – auch geräteübergreifend. Auf diese Datenverarbeitung haben wir grundsätzlich keinen Einfluss. Verantwortlich für diese Datenverarbeitung ist daher Google EU.
						</p>

						<p>
						Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.
						</p>

						<p>
						Ihre Google-Werbeeinstellungen können Sie auf der folgenden Webseite verwalten: <a href="https://adssettings.google.com/?hl=de">https://adssettings.google.com/?hl=de</a> (Diese Einstellung wird gelöscht, wenn Sie Ihre Cookies löschen)
						</p>

						<p>Wir verwenden:</p>

						<p>
						<i>Google Analytics</i>
						<br />
						Unsere Webseite verwendet den Dienst „Google Analytics“ von Google. Google Analytics verwendet Cookies (siehe oben unter „Cookies“), um uns eine Analyse der Benutzung der Webseite durch Webseitenbesucher zu ermöglichen. Die durch das Cookie erzeugten Informationen über die Benutzung dieser Webseite durch die Nutzer werden in der Regel an einen Server von Google in die USA übertragen und dort gespeichert. Unter anderem können übermittelt werden: Ihre IP-Adresse, Produkt- und Versionsinformationen über den verwendeten Browser und das Betriebssystem (sog. user agent), die Internetseite, von welcher Ihr Zugriff stattfand (sog. Referer), Datum und Uhrzeit der Anfrage und u.U. Ihr Internet-Service-Provider.
						</p>

						<p>
						Auf unserer Webseite wurde die IP-Anonymisierung aktiviert 2 , so dass Ihre übermittelte IP-Adresse vor der Übermittlung aus dem Geltungsbereich der DSGVO gekürzt und damit zum Teil unkenntlich gemacht, d.h. anonymisiert, wird. Nur in Ausnahmefällen wird die volle IP-Adresse zuerst an einen Server von Google in die USA übertragen und unmittelbar dort gekürzt.
						</p>

						<p>
						In unserem Auftrag wird Google die übermittelten Informationen benutzen, um die Nutzung unserer Webseite durch Sie auszuwerten, Berichte über die Webseitenaktivitäten zusammenzustellen und weitere mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen für uns zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
						</p>

						<p>
						Nähere Informationen zu Nutzungsbedingungen und Datenschutz bei Google Analytics finden Sie unter <a href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a> bzw. unter <a href="https://policies.google.com/technologies/partner-sites?hl=de">https://policies.google.com/technologies/partner-sites?hl=de</a>
						</p>

						<p>
						Sie können verhindern, dass die durch das Cookie erzeugten und auf die Nutzung der Webseite bezogenen Daten (inkl. IP-Adresse) an Google übermittelt bzw. von Google verarbeitet werden, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und in Ihrem Browser installieren. Der aktuelle Link lautet: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
						</p>

						<p>
						Sie können auch das Setzen von Cookies insgesamt verhindern (siehe oben „Wie Sie Cookies vermeiden können“).
						</p>

						<p>
						Die Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1 Buchstabe a) DSGVO (Einwilligung des Betroffenen). 4 Wir bitten Sie beim ersten Aufruf der Webseite über einen eingeblendeten Hinweistext um Ihr Einverständnis zur Verwendung des Dienstes. Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie in Ihrem Browser alle Cookies löschen. Wie dies in Ihrem Browser funktioniert, können Sie der Anleitung des Browsers entnehmen.
						</p>

						<p>
						<i>Google Fonts</i>
						<br />
						Unsere Webseite verwendet den externen Schriftart-Dienst „Google Fonts“ von Google. Dieser Dienst ermöglicht es uns, unsere Webseite auch bei sehr unterschiedlich konfigurierten Nutzer-Endgeräten einheitlich und ansprechend darzustellen, indem Schriftarten von einem externen Server statt vom Endgerät des Nutzers geladen werden. Dazu werden die benötigten Schriftarten in der Regel von einem Server von Google in den USA angefordert. Durch diese Anforderung werden unter anderem folgende Informationen an den Server von Google übermittelt und dort gespeichert: Ihre IP-Adresse, Produkt- und Versionsinformationen über den verwendeten Browser und das Betriebssystem (sog. user agent), die Internetseite, von welcher Ihr Zugriff stattfand (sog. Referer), Datum und Uhrzeit der Anfrage und u.U. Ihr Internet-Service-Provider.
						</p>

						<p>
						Die Rechtsgrundlage für die Verarbeitung Ihrer Daten in Bezug auf den Dienst „Google Fonts“ ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung). Das berechtigte Interesse ergibt sich aus unserem Bedarf an einer ansprechenden und einheitlichen Darstellung unseres Online-Angebots. Der Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten.
						</p>

						<p>
						<i>Google Tag Manager</i>
						<br />
						Unsere Webseite verwendet den Unterstützungsdienst „Google Tag Manager“ von Google. Dieser Dienst verarbeitet selbst keine personenbezogenen Daten. Der Google Tag Manager sorgt aber für die Auslösung von Skripten, die von anderen Diensten benötigt werden, um Daten zu erfassen. Ein Zugriff des Google Tag Manager auf diese Daten ist nicht möglich.
						</p>

						<p><b>Analyse-Tools</b></p>

						<p>
						Wir nutzen bestimmte Dienste, die Daten von Webseitenbesuchern aufzeichnen und uns zur Analyse zur Verfügung stellen. Diese Daten nutzen wir zur nutzungsorientierten Verbesserung unserer Webseite, unserer Dienstleistungen und Angebote. Insbesondere können folgende Informationen zum Besucherverhalten gesammelt werden: Ihre IP-Adresse, Produkt- und Versionsinformationen über den verwendeten Browser und das Betriebssystem (sog. user agent), die Internetseite von welcher Ihr Zugriff stattfand (sog. Referer), Datum und Uhrzeit der Anfrage und u.U. Ihr Internet-Service-Provider, ungefähre Herkunft (Land und Stadt), Sprache, Klicks auf Inhalte und Ansichtsdauer.
						</p>

						<p>
						Die Rechtsgrundlage für die Nutzung von Analyse-Tools ist Art. 6 Abs. 1 S. 1 Buchstabe a) DSGVO (Einwilligung des Betroffenen). 5 Wir bitten Sie beim ersten Aufruf der Webseite über einen eingeblendeten Hinweistext um Ihr Einverständnis zur Verwendung des Dienstes. Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie in Ihrem Browser alle Cookies löschen. Wie dies in Ihrem Browser funktioniert, können Sie der Anleitung des Browsers entnehmen.
						</p>

						<p>Wir verwenden:</p>

						<p>
						<i>Google Analytics</i>
						<br />
						Unsere Webseite verwendet den Dienst „Google Analytics“ der Firma Google. Siehe dazu die Datenschutzhinweise oben.
						</p>

						<p><b>Mediendienste</b></p>

						<p>
						Wir nutzen bestimmte Dienste, um unsere Webseite mit digitalen Inhalten zu füllen und zu ergänzen. Dazu nutzen wir in der Regel die Einbindungs-Funktionen von externen Plattformen. Hierdurch werden durch den Abruf der Inhalte vom Server des Anbieters Daten an diesen übermittelt und dort in der Regel gespeichert, z.B. Ihre IP-Adresse, Produkt- und Versionsinformationen über den verwendeten Browser und das Betriebssystem (sog. user agent), die Internetseite von welcher Ihr Zugriff stattfand (sog. Referer), Datum und Uhrzeit der Anfrage und u.U. Ihr Internet-Service-Provider. Nähere Informationen hierzu siehe oben unter „ Server-Log-Dateien “.
						</p>

						<p>
						Die Rechtsgrundlage für die Nutzung von Mediendiensten ist im Allgemeinen Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung), soweit bei dem jeweiligen Dienst nichts anderes angegeben ist. Das berechtigte Interesse ergibt sich aus unserem Interesse Ihnen eine inhaltlich und optisch ansprechende Webseite anbieten zu können. Der Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten.
						</p>

						<p>Wir verwenden:</p>

						<p>
						<i>Google Fonts</i>
						<br />
						Unsere Webseite verwendet den Dienst „Google Fonts“ der Firma Google. Siehe dazu die Datenschutzhinweise oben.
						</p>

						<h3>Sonstige Dienste</h3>

						<p><b>Polyfill</b></p>

						<p>
						Wir nutzen auf unserer Webseite den Dienst Polyfill der Firma The Financial Times Limited, Bracken House, 1 Friday Street, London, England, EC4m 9BT.
						</p>

						<p>
						Polyfill ermöglicht uns eine Webseite bereit zu stellen, dessen Funktionen verschiedenste Browser-Versionen unterstützt.
						</p>

						<p>
						Weitere Informationen zur Datennutzung bei Polyfill finden Sie in der Datenschutzerklärung des Anbieters: <a href="https://polyfill.io/v3/privacy-policy">https://polyfill.io/v3/privacy-policy</a>
						</p>

						<p>
						Die Rechtsgrundlage für die Nutzung von Polyfill ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung). Wir verfolgen mit dem Dienst das Interesse, Ihnen eine funktionierende Webseite zu bieten, deren Angebot sowohl auf neueren als auch älteren Browser-Versionen fehlerfrei dargestellt werden kann.
						</p>

						<p>
						Der Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter den Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten Kontaktdaten.
						</p>

						<br />
						<br />

						<p><i>Stand der Datenschutzerklärung: 08.06.2020</i></p>

						<p><i>Quelle: <a href="https://www.datenschutz-kanzlei.info/">Süddeutsche Datenschutzgesellschaft mbH</a></i></p>
					</div>
				</div>
			</div>
		</main>
	);
};

export default LandingGDPR;
