import React from "react";

const Timeline = () => {
	return (
		<main>
			<h1>PflegeSuite Versionsgeschichte & Roadmap</h1>

			<div className="container">
                <div className="card card-wide timeline">

                    <div className="year">
                        <div className="year-label">2018</div>
                        <div className="month">
                            <div className="release default" style={{'top': `${1 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">launch</icon>
                                    <span className="version">alpha</span>
                                    <span className="date">01.01.2018</span>
                                </div>
                                <div className="content">
                                    <span>
                                        Start der Alpha-Version, erste aktive Tester.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="month">
                            <div className="release default alt" style={{'top': `${22 / 0.28}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">upgrade</icon>
                                    <span className="version">0.9.0</span>
                                    <span className="date">22.02.2018</span>
                                </div>
                                <div className="content">
                                    <span>
                                        Lohnverwaltung der Mitarbeiter, benutzerdefinierte Visite in Tourenplan, allgemeine Funktionsverbesserungen, Leistungsoptimierungen.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="month">
                            <div className="release default" style={{'top': `${28 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">upgrade</icon>
                                    <span className="version">0.9.2</span>
                                    <span className="date">28.03.2018</span>
                                </div>
                                <div className="content">
                                    <span>
                                        Abwesenheitsmanagement für Mitarbeiter und Klienten, monatliche Dienstplanübersicht, Layoutverbesserungen, Leistungsoptimierungen.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release default alt" style={{'top': `${18 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">upgrade</icon>
                                    <span className="version">0.9.3</span>
                                    <span className="date">18.06.2018</span>
                                </div>
                                <div className="content">
                                    <span>
                                        Leistungsnachweise mit Tourenplan-Bindung, Tagesübersicht in Tourenplan, Verwaltung für Folgeverordnungen, Verordnungsbenachrichtigungen, Mitarbeiterkompetenzen, benutzerdefinierte Routen in Fahrtenbüchern, Verwaltung von Einsatzpauschalen.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release default" style={{'top': `${12 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">upgrade</icon>
                                    <span className="version">0.9.7</span>
                                    <span className="date">12.08.2018</span>
                                </div>
                                <div className="content">
                                    <span>
                                        Neue Funktionen für automatisierte Planung und Berechnung von Routen, zusätzliche Einstellungen, erweiterte Fahrtenbuchverwaltung, aktive / inaktive Mitarbeiter- und Klientenverwaltung nach Teams.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="month">
                            <div className="release default alt" style={{'top': `${24 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">upgrade</icon>
                                    <span className="version">0.9.8</span>
                                    <span className="date">24.09.2018</span>
                                </div>
                                <div className="content">
                                    <span>
                                        Geburtstagsbenachrichtigungen, Verwaltung von Klientenangehörigen, Medizinische verwaltung von Klienten, weitere Anpassungen der Leistungsplanung, Layout- und Performance Verbesserungen.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release red" style={{'top': `${21 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">local_hospital</icon>
                                    <span className="version">1.0.0</span>
                                    <span className="date">21.11.2018</span>
                                </div>
                                <div className="content">
                                    <b>Erste stabile Version, allgemeine Verfügbarkeit</b>
                                </div>
                            </div>
                        </div>
                        <div className="month">
                            <div className="release blue alt" style={{'top': `${10 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">mobile_friendly</icon>
                                    <span className="version">1.1.0</span>
                                    <span className="date">10.12.2018</span>
                                </div>
                                <div className="content">
                                    <b>Erste mobile Version für Smartphones</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="year">
                        <div className="year-label">2019</div>
                        <div className="month"></div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release purple" style={{'top': `${8 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">person</icon>
                                    <span className="version">1.2.0</span>
                                    <span className="date">08.03.2019</span>
                                </div>
                                <div className="content">
                                    <b>Mitarbeiter Konten & Zugang</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release green alt" style={{'top': `${13 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">person_search</icon>
                                    <span className="version">1.2.2</span>
                                    <span className="date">13.06.2019</span>
                                </div>
                                <div className="content">
                                    <b>MDK Ansicht</b>
                                </div>
                            </div>
                        </div>
                        <div className="month">
                            <div className="release gold" style={{'top': `${15 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">payments</icon>
                                    <span className="version">1.2.3</span>
                                    <span className="date">15.07.2019</span>
                                </div>
                                <div className="content">
                                    <b>Rechnungen & Übersicht</b>
                                </div>
                            </div>
                        </div>
                        <div className="month">
                            <div className="release gold alt" style={{'top': `${19 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">miscellaneous_services</icon>
                                    <span className="version">1.2.4</span>
                                    <span className="date">19.08.2019</span>
                                </div>
                                <div className="content">
                                    <b>Rentabilitätsberechnungen in Leistungsnachweisen</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release blue" style={{'top': `${1 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">mobile_screen_share</icon>
                                    <span className="version">1.2.5</span>
                                    <span className="date">01.11.2019</span>
                                </div>
                                <div className="content">
                                    <b>Navigation & Kontakte für Smartphones</b>
                                </div>
                            </div>
                        </div>
                        <div className="month">
                            <div className="release purple alt" style={{'top': `${24 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">group</icon>
                                    <span className="version">1.3.0</span>
                                    <span className="date">24.12.2019</span>
                                </div>
                                <div className="content">
                                    <b>Systemweite Teamfilterung</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="year">
                        <div className="year-label">2020</div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release gold" style={{'top': `${26 / 0.28}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">euro</icon>
                                    <span className="version">1.3.1</span>
                                    <span className="date">26.02.2020</span>
                                </div>
                                <div className="content">
                                    <b>Zuschlagstabelle</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release teal alt" style={{'top': `${28 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">track_changes</icon>
                                    <span className="version">1.3.3</span>
                                    <span className="date">28.04.2020</span>
                                </div>
                                <div className="content">
                                    <b>Tourenplan Änderungsverfolgung</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release grey" style={{'top': `${1 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">receipt_long</icon>
                                    <span className="version">1.3.4</span>
                                    <span className="date">01.06.2020</span>
                                </div>
                                <div className="content">
                                    <b>Leistungsverträge</b>
                                </div>
                            </div>
                        </div>
                        <div className="month">
                            <div className="release blue alt" style={{'top': `${5 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">developer_mode</icon>
                                    <span className="version">1.3.5</span>
                                    <span className="date">05.07.2020</span>
                                </div>
                                <div className="content">
                                    <b>Tourenplanung für Smartphones</b>
                                </div>
                            </div>
                        </div>
                        <div className="month">
                            <div className="release teal" style={{'top': `${20 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">date_range</icon>
                                    <span className="version">1.3.6</span>
                                    <span className="date">20.08.2020</span>
                                </div>
                                <div className="content">
                                    <b>Benutzerdefinierte wöchentliche Regelmäßigkeit der Einsätze</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release purple alt" style={{'top': `${2 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">timer</icon>
                                    <span className="version">1.4.0</span>
                                    <span className="date">2.11.2020</span>
                                </div>
                                <div className="content">
                                    <b>Mitarbeiter Zeitkonten</b>
                                </div>
                            </div>
                        </div>
                        <div className="month">
                            <div className="release green" style={{'top': `${8 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">archive</icon>
                                    <span className="version">1.4.1</span>
                                    <span className="date">08.12.2020</span>
                                </div>
                                <div className="content">
                                    <b>Archivierung von Leistungen</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="year">
                        <div className="year-label">2021</div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release grey alt" style={{'top': `${15 / 0.28}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">storage</icon>
                                    <span className="version">1.4.2</span>
                                    <span className="date">15.02.2021</span>
                                </div>
                                <div className="content">
                                    <b>Dokumentenverwaltung, Uploaden von eigenen Dokumenten</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release grey" style={{'top': `${13 / 0.31}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">published_with_changes</icon>
                                    <span className="version">1.4.3</span>
                                    <span className="date">13.05.2021</span>
                                </div>
                                <div className="content">
                                    <b>Änderungsverfolgung nach Benutzer</b>
                                </div>
                            </div>
                        </div>
                        <div className="month">
                            <div className="release green alt" style={{'top': `${15 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">feedback</icon>
                                    <span className="version">1.4.4</span>
                                    <span className="date">Juni 2021</span>
                                </div>
                                <div className="content">
                                    <b>Kundenspezifische Anmerkungen</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release teal" style={{'top': `${15 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">alt_route</icon>
                                    <span className="version">1.4.5</span>
                                    <span className="date">August 2021</span>
                                </div>
                                <div className="content">
                                    <b>AI-unterstützte Planerstellung & Routenoptimierung</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release gold alt" style={{'top': `${15 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">account_balance</icon>
                                    <span className="version">1.5.0</span>
                                    <span className="date">Oktober 2021</span>
                                </div>
                                <div className="content">
                                    <b>ZAD Schnittstelle</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release gold" style={{'top': `${15 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">insights</icon>
                                    <span className="version">1.5.2</span>
                                    <span className="date">Dezember 2021</span>
                                </div>
                                <div className="content">
                                    <b>Controlling</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="year">
                        <div className="year-label">2022</div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release gold alt" style={{'top': `${15 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">payments</icon>
                                    <span className="version">1.5.4</span>
                                    <span className="date">Februar 2022</span>
                                </div>
                                <div className="content">
                                    <b>Gehaltsabrechnung</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release teal" style={{'top': `${15 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">notifications_active</icon>
                                    <span className="version">1.5.6</span>
                                    <span className="date">April 2022</span>
                                </div>
                                <div className="content">
                                    <b>Erinnerungssystem</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release grey alt" style={{'top': `${15 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">live_help</icon>
                                    <span className="version">1.5.8</span>
                                    <span className="date">Juni 2022</span>
                                </div>
                                <div className="content">
                                    <b>Integrierte Hilfe, Tooltips, Videos</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release grey" style={{'top': `${15 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">published_with_changes</icon>
                                    <span className="version">1.6.0</span>
                                    <span className="date">August 2022</span>
                                </div>
                                <div className="content">
                                    <b>Veröffentlichung im Google Play Store</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release green alt" style={{'top': `${15 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">computer</icon>
                                    <span className="version">1.6.2</span>
                                    <span className="date">Oktober 2022</span>
                                </div>
                                <div className="content">
                                    <b>Digitale Leistungsnachweise</b>
                                </div>
                            </div>
                        </div>
                        <div className="month"></div>
                        <div className="month">
                            <div className="release green" style={{'top': `${15 / 0.30}%`}}>
                                <div className="-header">
                                    <icon className="material-icons">devices</icon>
                                    <span className="version">1.6.4</span>
                                    <span className="date">Dezember 2022</span>
                                </div>
                                <div className="content">
                                    <b>Digitale Kundenmappe</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="year">
                        <div className="year-label">2023</div>
                    </div>

                </div>
            </div>
		</main>
	);
};

export default Timeline;
