import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useTransition, animated } from "react-spring";

import LandingMain from "./components/landing-main";
import LandingFunctions from "./components/landing-functions";
import LandingGDPR from "./components/landing-gdpr";
import LandingImpressum from "./components/landing-impressum";
import LandingSupport from "./components/landing-support";
import LandingTerms from "./components/landing-terms";
import LandingTutorials from "./components/landing-tutorials";
import Contract from "./components/contract";
import ContractDataprotect from "./components/contract-dataprotect";
import Timeline from "./components/timeline";
import Error from "./components/error";

export default function App() {
	const location = useLocation();

	const transitions = useTransition(location, location => location.pathname, {
		from: { opacity: 0, position: "absolute", marginTop: "-3rem", width: "100%" },
		enter: { opacity: 1, position: "relative", marginTop: "0rem", width: "100%" },
		leave: {
			opacity: 0,
			position: "absolute",
			top: "12rem",
			marginTop: "10rem",
			width: "100%"
		}
	});

	return transitions.map(({ item, key, props }) => (
		<animated.div key={key} style={props}>
			<Switch location={item}>
				<Route path="/" component={LandingMain} exact />
				<Route path="/index.html" component={LandingMain} exact />
				<Route path="/functions" component={LandingFunctions} exact />
				<Route path="/gdpr" component={LandingGDPR} exact />
				<Route path="/impressum" component={LandingImpressum} exact />
				<Route path="/support" component={LandingSupport} exact />
				<Route path="/terms" component={LandingTerms} exact />
				<Route path="/tutorials" component={LandingTutorials} exact />
				<Route path="/contract" component={Contract} exact />
				<Route path="/dataprotect" component={ContractDataprotect} exact />
				<Route path="/timeline" component={Timeline} exact />
				<Route component={Error} />
			</Switch>
		</animated.div>
	));
}
