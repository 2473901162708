import React from "react";

const LandingSupport = () => {
	return (
		<main>
			<h1>Support & FAQ</h1>

			<div className="container green">
				<div className="card card-wide">
					<icon className="material-icons">contact_support</icon>

					<div className="card-text">
						<h3>Wie kann ich den PflegeSuite Kundendienst erreichen?</h3>

						<p>
							Der PflegeSuite Kundendienst ist unter <b><a href="mailto:team@pflegesuite.de">team@pflegesuite.de</a></b> zu erreichen.
						</p>

						<h3>Wie viel kostet PflegeSuite?</h3>

						<p>
							Unabhängig von der Größe Ihres Unternehmens, der Anzahl der Arbeitsstationen, der Benutzer usw. ist
							PflegeSuite zum Pauschalpreis von <b>400 € / Monat (+ MwSt.)</b> erhältlich. Es sind keine zusätzlichen
							Module erforderlich, keine Installations- oder Einrichtungsgebühren. Das System steht Ihnen sofort zur
							Verfügung. Außerdem bekommen Sie unverbindlich einen Monat der Nutzung kostenlos.
						</p>

						<h3>Gibt es eine mobile Version für Handys / Tablets?</h3>

						<p>
							Die PflegeSuite ist <b>mit allen modernen Smartphones und Tablets kompatibel</b> und bietet 100%
							Funktionalität für das Management unterwegs. Sie erhalten Zugriff über eine Web-App. Das bedeutet, dass
							Sie keine Apps auf Ihrem Telefon oder Tablet installieren müssen, und es funktioniert auf allen Geräten,
							ob Android, iOS oder anderen.
						</p>

						<h3>Wie erfolgt das Training für die neuen Benutzer und wieviel kostet es? </h3>

						<p>
							Die PflegeSuite ist <b>sehr einfach zu bedienen</b> - wenn Sie mit einem Smartphone umgehen können, können
							Sie auch mit unserem System umgehen. Auf unserer Website stellen wir Schulungsmaterialien im Videoformat
							zur Verfügung, um Sie mit allen grundlegenden Funktionen des Systems zu unterstützen. Sollten Sie jedoch
							weitere Fragen haben oder doch eine persönliche Führung durch alle Funktionen wünschen,{" "}
							<b>steht Ihnen unser Support jederzeit zur Verfügung</b>. Wir sind für Sie da wann immer Sie uns brauchen
							– und das <b>kostenlos</b>.
						</p>

						<h3>Was ist mit Upgrades und Updates? Wie sehen Zeitplan und Kosten aus?</h3>

						<p>
							Wir veröffentlichen <b>regelmäßig neue Patches</b>. Diese enthalten neue Funktionen und
							Funktionsaktualisierungen. Größere Updates werden alle 2-3 Monate veröffentlicht, während kleinere Patches
							und Fehlerbehebungen je nach Bedarf implementiert werden. Informationen zu den neuesten Ergänzungen und
							Änderungen finden Sie in unseren Patchnotizen. Alle unsere Updates sind <b>immer kostenlos</b>.
						</p>

						<h3>Was ist, wenn ich eine zusätzliche Funktion brauche, die nicht verfügbar ist?</h3>

						<p>
							PflegeSuite entwickelt sich ständig weiter und Ihre Vorschläge sind für uns sehr wertvoll. Bitte zögern
							Sie nicht, uns <b>Ihr Feedback und Ihre Ideen mitzuteilen</b>. Wir werden sie uns ansehen und prüfen, ob
							wir neue Funktionen implementieren können, um Ihren speziellen Anwendungsfall zu berücksichtigen.
						</p>

						<h3>Kann ich meine bestehenden Kunden und Mitarbeiter zur PflegeSuite übertragen?</h3>

						<p>
							Um den Aufwand für den Übergang zu PflegeSuite zu minimieren, können wir für Sie die meisten Ihrer Daten
							übertragen, z. B. Ihre Listen von Patienten, Mitarbeitern, Fahrzeugen, benutzerdefinierten Diensten usw.,{" "}
							<b>sofern sie in einem kompatiblen Format (XLS, CSV usw.) vorliegen</b>. Bitte kontaktieren Sie uns
							bezüglich Ihres speziellen Falles. In bestimmten Fällen können wir dies kostenlos tun. Wenn Ihr aktuelles
							System den Export von Daten nicht unterstützt oder wenn die Daten nicht in einem geeigneten Format
							vorliegen, muss die Übertragung manuell durchgeführt werden.
						</p>

						<h3>Was ist die Mindestdauer des Vertrags, um PflegeSuite zu nutzen?</h3>

						<p>
							Es gibt keine Mindestdauer - <b>wir berechnen monatlich</b> und Sie können den Service jederzeit kündigen.
						</p>
					</div>
				</div>
			</div>
		</main>
	);
};

export default LandingSupport;
