import React from "react";
import { Link } from "react-router-dom";

const Confirmation = () => {
	return (
		<>
			<div className="block">
				<p>
					Das Formular wurde erfolgreich übermittelt, vielen Dank!
					<br />
					Wir werden Sie in Kürze kontaktieren.
					<iframe name="confirm" title="confirm" className="form-submit" />
				</p>
			</div>
			<div className="buttons">
				<Link to="support">
					<button>
						<icon className="material-icons">check</icon>Schließen
					</button>
				</Link>
			</div>
		</>
	);
};

export default Confirmation;
