import React from "react";

import "../document.css";

import { ReactComponent as Logo } from "../img/logo-inline.svg";
import PinnacleLogo from "../img/pinnacle-logo.gif";

const ContractDataprotect = () => {
	return (
		<main>
			<div className="card-text contract">
				<ps-header>
					<Logo title="PflegeSuite" />

					<h2>Vertrag zur Auftragsverarbeitung gemäß Art. 28 DSGVO</h2>

					<img src={PinnacleLogo} alt="PinnacleSoft" />
				</ps-header>

				<br />
				<br />

				<tmp-row>
					<tmp-column>
						<tmp-h3>Daten Kunde:</tmp-h3>

						<tmp-field class="label-top">
							<label>Firmenname</label>
							<div />
						</tmp-field>

						<tmp-row>
							<tmp-field class="label-top">
								<label>IK #</label>
								<div />
							</tmp-field>

							<tmp-field class="label-top">
								<label>Tel #</label>
								<div />
							</tmp-field>

							<tmp-field class="label-top">
								<label>Fax #</label>
								<div />
							</tmp-field>
						</tmp-row>

						<tmp-field class="label-top">
							<label>Adresse</label>
							<div />
						</tmp-field>
					</tmp-column>

					<tmp-column>
						<tmp-h3>Daten Pinnacle Soft:</tmp-h3>

						<tmp-field class="label-top">
							<label>Firmenname</label>
							<div>Pinnacle Soft</div>
						</tmp-field>

						<tmp-row>
							<tmp-field class="label-top">
								<label>Tel #</label>
								<div>+49 163 7726 773</div>
							</tmp-field>

							<tmp-field class="label-top">
								<label>Fax #</label>
								<div />
							</tmp-field>
						</tmp-row>

						<tmp-field class="label-top">
							<label>Adresse</label>
							<div>Langelandweg 6 24109 Kiel</div>
						</tmp-field>
					</tmp-column>
				</tmp-row>

				<h3>§1 Vertragsgegenstand</h3>

				<p>
					Gegenstand des Vertrags ist die Bereitstellung der Software pflegesuite.de. Im Rahmen dieses Vertrages hat Pinnacle Soft die Möglichkeit Daten zu verarbeiten (zu speichern, zu ändern, zu übermitteln und zu löschen).
				</p>

				<p>
					Gegenstand des Vertrages ist nicht die originäre Nutzung oder Verarbeitung personenbezogener Daten durch Pinnacle Soft. Im Zuge der Leistungserbringung der Firma Pinnacle Soft als Dienstleister im Bereich Hosting, des Supports bzw. Administration der Software pflegesuite.de, kann ein Zugriff auf personenbezogene Daten jedoch nicht ausgeschlossen werden.
				</p>

				<p>
					In Ergänzung zu dem zwischen den Parteien geschlossenen Vertrag/Verträge konkretisieren die Vertragsparteien mit dem vorliegenden Auftragsverarbeitungsvertrag gegenseitig die Pflichten im generellen Umgang mit den Daten des Kundens.
				</p>

				<h3>§2 Laufzeit</h3>

				<p>
					Die Laufzeit des Vertrages richtet sich nach der Laufzeit der Nutzung der Software pflegesuite.de. Der Auftrag endet, wenn der Kunde keine Leistungen von Pinnacle Soft mehr in Anspruch nimmt.
				</p>

				<h3>§3 Art und Zweck der Verarbeitung</h3>

				<p>
					Art und Zweck der Verarbeitung der vorgesehenen Erhebung, Verarbeitung und/oder Nutzung ergeben sich aus dem zwischen den Vertragsparteien bestehenden Vertrag.
				</p>

				<p>
					Pinnacle Soft ist verpflichtet, die ihr zur Verfügung gestellten personenbezogenen Daten ausschließlich zu den vertraglich vereinbarten Leistungen zu verwenden. Pinnacle Soft ist es gestattet, verfahrens- und sicherheitstechnisch erforderliche Zwischen-, Temporär- oder Duplikatsdateien zur leistungsgemäßen Erhebung, Verarbeitung, und/oder Nutzung der personenbezogenen Daten zu erstellen, soweit dieses nicht zu einer inhaltlichen Umgestaltung führt. Pinncale Soft ist nicht gestattet, unautorisiert Kopien der personenbezogenen Daten zu erstellen.
				</p>

				<p>
					Soweit seitens Pinnacle Soft eine Erhebung, Verarbeitung und/oder Nutzung der Daten erfolgt, geschieht dieses ausschließlich im Gebiet der Bundesrepublik Deutschland.
				</p>

				<h3>§4 Art der personenbezogenen Daten und Kreis der betroffenen Personen</h3>

				<p>
					Gegenstand der Erhebung, Verarbeitung und/oder Nutzung der Daten des Kundens sind folgende Daten: Klientendaten, Bilddaten, Gesundheitsdaten, 		Mitarbeiterdaten, Bankverbindungsdaten, Kommunikationsdaten (z.B. Telefon, E-Mail), bzw. Sonstige Daten. Die Daten speichert der Kunde oder ein von ihm autorisierter Nutzer von pflegesuite.de.
				</p>

				<p>
					Der Kreis, der durch den Umgang mit den Daten betroffenen Personen umfasst: Klienten, Interessenten, Beschäftigten, Angehörige, Betreuer, Dienstleister, Ansprechpartner, bzw. Sonstige Betroffene.
				</p>

				<h3>§5 Pflichten der Pinnacle Soft</h3>

				<p>
					Soweit seitens Pinnacle Soft eine Erhebung, Verarbeitung und/oder Nutzung der Daten erfolgt, ist dieses nur zulässig im Rahmen der vertraglichen Vereinbarungen zwischen Kunde und Pinnacle Soft. Soweit Pinnacle Soft Zugriff auf Daten des Kundens hat, verwendet er diese nicht für vertragsfremde Zwecke, insbesondere gibt er diese an Dritte nur weiter soweit hierfür eine gesetzliche Verpflichtung besteht. Kopien von Daten dürfen nur mit Zustimmung des Kundens erstellt werden. Hiervon ausgenommen sind Sicherheitskopien, soweit sie zur Gewährleistung einer ordnungsgemäßen Datenverarbeitung oder Erfüllung vertraglicher oder gesetzlicher Verpflichtungen erforderlich sind.
				</p>

				<p>
					Pinnacle Soft stellt die Wahrung der Vertraulichkeit entsprechend Art. 28 Abs. 3 2 lit. b, 29, 32 Abs. 4 DSGVO sicher. Alle Personen, die auftragsgemäß auf die unter Punkt 4.1 angeführten Daten des Kundens zugreifen können, müssen auf die Vertraulichkeit verpflichtet und über die, aus diesem Auftrag ergebenden, besonderen Datenschutzpflichten sowie die bestehenden Weisungs- und Zweckbindungen belehrt werden.
				</p>

				<p>
					Pinnacle Soft teilt dem Kunden unverzüglich Störungen, Verstöße des Pinnacle Soft oder bei ihm beschäftigten Personen sowie gegen datenschutzrechtliche Bestimmungen oder die im Auftrag getroffenen Festlegungen sowie den Verdacht auf Datenschutzverletzung oder Unregelmäßigkeiten bei der Verarbeitung personenbezogener Daten mit. Dies gilt vor allem auch im Hinblick auf eventuelle Melde- und Benachrichtigungspflichten des Kundens nach Art. 33 und Art. 34 DSGVO. Pinnacle Soft sichert zu, den Kunden erforderlichenfalls bei seinen Pflichten nach Art. 33 und Art. 34 DSGVO angemessen zu unterstützen (Art. 28 Abs. 3 Satz 2lit. f DSGVO). Meldungen nach Art. 33 oder Art. 34 DSGVO für den Kunden darf die nach der vorherigen Weisung gem. Punkt 9 dieses Vertrages durchführen.
				</p>

				<p>
					Pinnacle Soft verwendet die überlassenen Daten für keine anderen Zwecke als die der Vertragserfüllung.
				</p>

				<p>
					Pinnacle Soft nennt dem Kunden den Ansprechpartner für, im Rahmen des Vertrages, anfallenden Datenschutzfragen.
				</p>

				<p>
					Pinnacle Soft verpflichtet sich einen Datenschutzbeauftragten zu bestellen, soweit dieses vom Gesetz vorgeschrieben wurde.
				</p>

				<p>
					Pinnacle Soft berichtigt, löscht oder sperrt die vertragsgegenständlichen Daten, wenn der Kunde dieses anweist. Entstehen dabei durch Aufwand oder Gebühren dritter Kosten, trägt diese der Kunde. Die datenschutzkonforme Vernichtung von Datenträgern und sonstigen Materialien übernimmt Pinnacle Soft auf Grund einer Einzelbeauftragung durch den Kunden, sofern nicht im Vertrag bereits vereinbart. Im Falle von Test- und Ausschlussmaterialien ist eine Einzelbeauftragung nicht erforderlich. In besonderen, vom Kunde bestimmenden Fällen, erfolgt eine Aufbewahrung bzw. Übergabe.
				</p>

				<h3>§6 Technische und organisatorische Maßnahmen</h3>

				<p>
					Pinnacle Soft gestaltet in ihren Verantwortungsbereich die innerbetriebliche Organisation so, dass sie den Anforderungen des Datenschutzes gerecht wird. Sie trifft dabei technische und organisatorische Maßnahmen zur angemessenen Sicherung der Daten vor Missbrauch und Verlust, um den Anforderungen des DSGVO zu entsprechen.
				</p>

				<p>
					Die Parteien sind sich einig, dass technische und organisatorische Maßnahmen dem technischen Fortschritt und der Weiterentwicklung unterliegen. Insoweit ist es Pinnacle Soft gestattet, alternative adäquate Maßnahmen umzusetzen. Sie muss den Kunden hierüber auf die Anfrage informieren und sicherstellen, dass das Sicherheitsniveau der festgelegten Maßnahmen nicht unterschritten wird. Pinnacle Soft hat die Sicherheit gem. Art. 28 Abs. 3 lit. c, Art. 32 DSGVO insbesondere die Verbindung mit Art. 5 Abs. 1, Abs. 2 DSGVO herzustellen. Insgesamt handelt es sich bei den zu treffenden Maßnahmen um Maßnahmen der Datensicherheit und zur Gewährleistung eines dem Risiko angemessenen Schutzniveaus hinsichtlich der Vertraulichkeit, der Integrität der Verfügbarkeit sowie der Belastbarkeit der Systeme. Wesentliche Änderungen sind zu dokumentieren.
				</p>

				<h3>§7 Unterauftragsverhältnisse</h3>

				<p>
					Der Kunde ist damit einverstanden, dass Pinnacle Soft zur Erfüllung ihren vertraglich vereinbarten Leistungen verbundene Unternehmen zur Leistungserfüllung heranzieht bzw. dritte Unternehmen mit Leistungen beauftragt.
				</p>

				<p>
					Erteilt Pinnacle Soft Aufträge an Subunternehmen, so obliegt es Pinnacle Soft, ihre Pflichten aus diesem Vertrag dem Subunternehmen zu übertragen.
				</p>

				<p>
					Ein zustimmungspflichtiges Subunternehmerverhältnis liegt nicht vor, wenn Pinnacle Soft Dritte im Rahmen einer Nebenleistung zur Hauptleistung beauftragt, wie beispielsweise Post- und Versandleistungen oder Wartung.
				</p>

				<p>
					Zur Zeit sind für Pinnacle Soft die in Anlage mit Namen, Anschrift und Auftragsinhalt bezeichnete Subunternehmen, mit der Verarbeitung von personenbezogenen Daten in dem dort genannten Umfang, beschäftigt.
				</p>

				<h3>§8 Pflichten des Kundens</h3>

				<p>
					Der Kunde ist für die Einhaltung der für ihn einschlägigen datenschutzrechtlichen Regelungen verantwortlich.
				</p>

				<p>
					Der Kunde hat der Pinnacle Soft unverzüglich und vollständig zu informieren, wenn er Verstöße der Pinnacle Soft gegen datenschutzrechtliche Bestimmungen feststellt.
				</p>

				<p>
					Den Kunden treffen die sich aus Art. 24 DSGVO und Art. 13 und Art. 14 DGSVO ergebenen Informationspflichten.
				</p>

				<p>
					Die Daten werden nach dem Ende des jeweiligen Vertrages gelöscht. Es obliegt dem Kunde, Sicherungskopien von seinen Daten anzufertigen und die Daten vor Vertragsende umzuziehen. Der Kunde hat selbst Zugriff auf seine Daten. Eine Pflicht der Pinnacle Soft zur Herausgabe besteht daher nicht.
				</p>

				<h3>§9 Weisungsbefugnis des Kundens</h3>

				<p>
					Mündliche Weisungen bestätigt der Kunde unverzüglich (mind. Textform).
				</p>

				<p>
					Pinnacle Soft hat den Kunden unverzüglich zu informieren, wenn sie der Meinung ist, eine Weisung verstoße gegen die Datenschutzvorschriften. Pinnacle Soft ist berechtigt, die Durchführung der entsprechenden Weisung solange auszusetzen, bis sie durch den Kunden bestätigt oder geändert ist.
				</p>

				<h3>§10 Kontrollrechte des Kundens</h3>

				<p>
					Der Kunde kann sich auf seine Kosten vor der Aufnahme der Datenverarbeitung und sodann regelmäßig über die technischen und organisatorischen Maßnahmen der Pinnacle Softs informieren und das Ergebnis dokumentieren. Hierfür kann er z.B. Auskünfte der Pinnacle Soft einholen, sich ein ggf. vorhandenes Testat eines Sachverständigen vorlegen lassen oder nach rechtzeitiger Abstimmung zu den üblichen Geschäftszeiten ohne Störung des Betriebslaufes persönlich prüfen oder durch einen sachkundigen Dritten prüfen lassen, sofern dieser nicht in einem Wettbewerbsverhältnis zur Pinnacle Soft steht. Bei der Prüfung muss mindestens ein Mitarbeiter der Pinnacle Soft anwesend sein. Jeder Schritt ist mit diesem abzuklären. Entstandene Kosten für Anfahrt und Arbeitszeit der Pinnacle Soft trägt der Kunde. Für die Unterstützung bei der Durchführung einer Prüfung darf Pinnacle Soft eine Vergütung verlangen. Der Aufwand der Prüfung ist für Pinnacle Soft grundsätzlich auf einen Tag pro Kalenderjahr begrenzt.
				</p>

				<p>
					Pinnacle Soft verpflichtet sich, dem Kunde auf schriftliche Anforderung innerhalb einer angemessenen Frist alle Auskünfte und Nachweise zur Verfügung zu stellen, die zur Durchführung einer Kontrolle erforderlich sind. Dafür entstandenen Kosten trägt der Kunde.
				</p>

				<h3>§11 Haftung</h3>

				<p>
					Hinsichtlich der Haftung wird auf Art. 82 DSGVO verwiesen.
				</p>

				<h3>§12 Sonstiges</h3>

				<p>
					Vereinbarungen zu den technischen und organisatorischen Maßnahmen sowie Kontroll- Prüfungsunterlagen (auch zu Subunternehmen) sind von beiden Vertragspartner für ihre Geltungsdauer und anschließend noch für drei volle Kalenderjahre aufzubewahren.
				</p>

				<p>
					Im Übrigen gelten die Allgemeine Geschäftsbedingungen (kurz AGB) der Pinnacle Soft.
				</p>

				<p>
					Es gilt deutsches Recht.
				</p>

				<h3>§13 Anlage - Auflistung der Unterauftragsnehmer</h3>

				<p>
					(1) - Contabo GmbH, Aschauer Straße 32a, 81549 München (www.contabo.de). Leistungsbeschreibung: Hosting der Webseite pflegesuite.de
				</p>

				<br />
				<br />
				<br />
				<br />

				<tmp-row>
					<tmp-column>
						<tmp-row>
							<tmp-field class="label-bottom">
								<label>Datum, Ort</label>
								<div />
							</tmp-field>

							<tmp-field class="label-bottom">
								<label>Unterschrift Kunde</label>
								<div />
							</tmp-field>
						</tmp-row>
					</tmp-column>

					<tmp-column>
						<tmp-row>
							<tmp-field class="label-bottom">
								<label>Datum, Ort</label>
								<div />
							</tmp-field>

							<tmp-field class="label-bottom">
								<label>Unterschrift Pinnacle Soft</label>
								<div />
							</tmp-field>
						</tmp-row>
					</tmp-column>
				</tmp-row>
			</div>

			<br />
			<br />
		</main>
	);
};

export default ContractDataprotect;
