import React from "react";
import useStateWithLocalStorage from "../localStorage";

const Gdpr = () => {

    const [isClosed, setClosed] = useStateWithLocalStorage('cookiesClosed', 'false');
    const closed = isClosed === 'true';

    const [isSelected, setSelected] = useStateWithLocalStorage('cookiesAllowed', 'true');
    const selected = isSelected === 'true';

    return (
        <gdpr className={closed ? "closed" : ""}>
            <div className={"gdpr-content " + (selected ? "" : "disabled")}>
                <p className="gdpr-title"><b>Diese Webseite verwendet Cookies</b></p>
                
                <p>
                    Wir verwenden Cookies, um die Zugriffe auf unsere Website zu analysieren und Ihnen damit den bestmöglichen
                    Service zu gewährleisten. Wenn Sie auf "Zustimmen" klicken oder diesen Banner schließen, stimmen Sie der
                    Cookie-Nutzung zu.
                </p>

                <div className="gdpr-cookies">

                    <div className="cookie-usage">
                        <b>Cookie-Nutzung:</b>
                        <br/>
                        Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor 
                        allem mit Cookies und mit sogenannten Analyseprogrammen. Bei Cookies handelt es sich um kleine Dateien,
                        welche auf Ihrem Endgerät gespeichert werden. Ihr Browser greift auf diese Dateien zu. Durch den Einsatz
                        von Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser Webseite. Außerdem verwenden wir
                        Notwendige Cookies, die uns dabei helfen diese Webseite nutzbar zu machen, indem sie Grundfunktionen wie
                        Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die meisten der von uns
                        verwendeten Cookies werden nach Ende Ihres Besuchs automatisch gelöscht.
                    </div>

                    <div className="cookie-controls green">
                        <ps-slider>
                            <span className="ps-checked">Cookies erlauben:</span>

                            <input type="checkbox" className="switch-input" readOnly checked={selected} />

                            <label className="switch-label" onClick={() => setSelected((!selected).toString())}/>
                        </ps-slider>
                        <button onClick={() => setClosed('true')}>Zustimmen</button>
                    </div>

                </div>

                <div onClick={() => setClosed('true')} className="close">
                    <icon className="material-icons">close</icon>
                </div>

                <div onClick={() => setClosed('false')} className="close open">
                    <icon className="material-icons">expand_less</icon>
                </div>
            </div>
        </gdpr>
    );
};

export default Gdpr;
