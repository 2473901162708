import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

import "./main.css";
import "./landing.css";

import App from "./App";
import Header from "./components/header";
import Footer from "./components/footer";
import GDPR from "./components/gdpr";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
	<BrowserRouter basename="/static/landing_page/">
		<Header />
		<App />
		<Footer />
		<GDPR />
	</BrowserRouter>,
	document.getElementById("root")
);

serviceWorker.register();
