import React from "react";

import FadeUp from "./fade-up";

import Tourenplan from "../img/screenshots/tourenplan.jpg";
import Dienstplan from "../img/screenshots/dienstplan.jpg";
import Klienten from "../img/screenshots/klienten.jpg";
import LNW from "../img/screenshots/lnw.jpg";
import Fahrten from "../img/screenshots/fahrtenkontrolle.jpg";
import Resources from "../img/screenshots/mitarbeiter.jpg";

const LandingFunctions = () => {
	return (
		<main>
			<h1>Funktionen und Eigenschaften</h1>

			<div className="container cards-alternating">
				<div className="card card-wide teal">
					<icon className="material-icons">directions</icon>

					<div className="card-wide-head">
						<img src={Tourenplan} alt="Tourenplan" />
					</div>

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">directions</icon>Tourenplanung
						</h2>

						<p>
							<b>
								Wahrscheinlich das Herzstück im Leben eines Pflegedienstes. Der von PflegeSuite bereitgestellte
								Tourenplan bietet Benutzerfreundlichkeit, Übersichtlichkeit und viele nützliche Funktionen.
							</b>
						</p>

						<br />

						<ul>
							<li>Umfassende Liste aller verfügbaren Kundenbesuche</li>
							<li>Automatische Berechnung der Besuchsdauer, Routen zwischen Besuchen, Kilometerstand</li>
							<li>Anpassung des Startortes, der Transportmethoden und des Besuchsdauers von Mitarbeitern</li>
							<li>Befähigungsüberprüfungen zwischen Besuchen und Mitarbeitern ermöglichen eine genauere Planung</li>
							<li>Abwesende Kunden und Mitarbeiter werden bei der Planung berücksichtigt</li>
							<li>Schichtübersicht mit Besuchen und Routenvorschlägen auf der Karte</li>
							<li>
								Gleichzeitiges Öffnen und Bearbeiten von zwei Mitarbeitern, um Schichten und Besuche einfach zu tauschen
								oder zu kopieren
							</li>
							<li>Druckansicht des gesamten Arbeitsplans eines Mitarbeiters mit Adressen und anderen Besuchsdetails</li>
							<li>Rentabilitätsberechnungen basierend auf Daten von Besuchen und den Löhnen von Mitarbeitern</li>
							<li>Umfassender und klarer Überblick über alle Besuche aller Mitarbeiter an einem bestimmten Tag</li>
							<li>Einfaches Kopieren einer ganzen Woche in einem Mitarbeiterplan oder zwischen Mitarbeitern</li>
							<li>Bevorzugte Zeitfenster für Besuche ermöglichen eine bessere Planung</li>
							<li>Benutzerdefinierte Sondereinsätze mit Vorlagen, Pausen usw.</li>
						</ul>
					</div>
				</div>

				<FadeUp className="card card-wide teal">
					<icon className="material-icons">assignment</icon>

					<div className="card-wide-head">
						<img src={Dienstplan} alt="Dienstplan" />
					</div>

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">assignment</icon>Dienstplanung
						</h2>

						<p>
							<b>
								Der Dienstplan ist für die Übersicht und die strategische Planung essentiell. Es dient auch als ein
								wichtiges Hilfsmittel zur Berechnung der Arbeitsbelastung und der Überstunden der Mitarbeiter.
							</b>
						</p>

						<br />

						<ul>
							<li>Mitarbeiter- und Schichtdaten werden immer mit dem Tourenplan synchronisiert</li>
							<li>Wöchentliches Planungsformat für eine einfachere Kontrolle der Mitarbeiterschichten</li>
							<li>Monatsansicht für Druck- und Übersichtszwecke</li>
							<li>Sehr bequeme Handhabung beim Hinzufügen, Kopieren und Ändern von Schichten</li>
							<li>Ist- und Soll-Dienstpläne, wobei Ist-Werte automatisch aus den Tourenplandaten übernommen werden</li>
							<li>Markierte Arbeitszeitstunden, geplante Stunden und Abweichungen</li>
							<li>Schichtübersicht mit Besuchen und Routenvorschlägen auf der Karte</li>
							<li>
								Einfaches Kopieren von individuellen Schichten, einzelnen Wochen oder einer Woche für alle Angestellten
							</li>
							<li>Markieren von abwesenden und inaktiven Mitarbeitern</li>
							<li>Speichern der zuvor erstellten Dienstpläne als Referenz</li>
						</ul>
					</div>
				</FadeUp>

				<FadeUp className="card card-wide green">
					<icon className="material-icons">face</icon>

					<div className="card-wide-head">
						<img src={Klienten} alt="Klienten" />
					</div>

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">face</icon>Klientenverwaltung
						</h2>

						<p>
							<b>
								PflegeSuite bietet einen integrierten Ansatz für das Kundenmanagement, der alles, was Sie benötigen,
								vereint.
							</b>
						</p>

						<br />

						<ul>
							<li>Überblick und Management von aktuellen, ehemaligen und potenziellen Kunden</li>
							<li>Kundendaten, Versicherungsinformationen, Liste der Angehörigen, relevante medizinische Daten</li>
							<li>Die zeitplanbasierte Verwaltung von Leistungsplänen bietet einen klaren und sachlichen Überblick</li>
							<li>
								Einfache Erstellung, Teilung und Kopieren von Servisplänen, Erstellung von Vorlagen für zukünftige
								Verwendungen
							</li>
							<li>
								Ein-Klick-Generierung von Kostenvoranschlägen aus vorhandenen Leistungs- und Tourplänen oder Vorlagen
							</li>
							<li>Verordnungsverwaltung mit einer Übersicht aller Kundenvorgaben auf einen Blick</li>
							<li>Bequeme Verfolgung von aktiven, früheren und noch nicht genehmigten Verordnungen</li>
							<li>Ein-Klick-Generierung von Folgeverordnungen </li>
							<li>Vermeidung von Leistungsüberschneidungen zur Verhinderung von menschlichen Fehlern</li>
							<li>
								Durch die Änderung des vorhandenen Leistungsplans werden geplante Besuche im Tourenplan automatisch
								aktualisiert
							</li>
						</ul>
					</div>
				</FadeUp>

				<FadeUp className="card card-wide green">
					<icon className="material-icons">check_box</icon>

					<div className="card-wide-head">
						<img src={LNW} alt="Leinstungsnachweise" />
					</div>

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">check_box</icon>Leistungsnachweise
						</h2>

						<p>
							<b>
								Vollständig automatisiert und mit den Daten in Ihrem Tourenplan synchronisiert, wird der PflegeSuite
								Leistungsnachweis mit nur wenigen Klicks generiert.
							</b>
						</p>

						<br />

						<ul>
							<li>Klare und kompakte Ansicht für einfache Bedienung und Kontrolle</li>
							<li>Die Visitenkontrolle zeigt alle geplanten Besuche auf einen Blick gemäß den Angaben im Tourenplan</li>
							<li>Vergleichen Sie geplante und reale Ankunftszeiten gemäß dem Tourenplan</li>
							<li>Aktivieren Sie die Hervorhebung der Abwesenheitszeiten des Patienten</li>
							<li>Listet alle Mitarbeiter auf, die den Patienten besuchen, um einen besseren Überblick zu erhalten</li>
							<li>Leistungsnachweise einzeln, für Teams oder für alle Kunden gleichzeitig anzeigen und drucken</li>
						</ul>
					</div>
				</FadeUp>

				<FadeUp className="card card-wide gold">
					<icon className="material-icons">near_me</icon>

					<div className="card-wide-head">
						<img src={Fahrten} alt="Fahrtenkontrolle" />
					</div>

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">near_me</icon>Fahrtenkontrolle
						</h2>

						<p>
							<b>
								Die Fahrtenbücher sind ein langwieriger Teil des Alltages eines Pflegedienstes. PflegeSuite bietet ein
								leistungsfähiges Instrument zur Verfolgung und Kontrolle ihrer Fahrten.
							</b>
						</p>

						<br />

						<ul>
							<li>
								Erstellen Sie mit einem Klick Fahrtenbücher für Mitarbeiter, basierend auf den Daten im Tourenplan
							</li>
							<li>Fahrtenbücher für Privatfahrzeuge von Mitarbeitern oder Dienstfahrzeuge</li>
							<li>Private Mitarbeiterfahrten zur und von der Arbeit werden bei Bedarf automatisch hinzugefügt</li>
							<li>Sie können benutzerdefinierte Arbeits- oder Privatfahrten bequem hinzufügen, entfernen und ändern</li>
							<li>
								Erstellen Sie benutzerdefinierte Routen basierend auf Ihrem Adressbuch von Kunden, Mitarbeitern, Ärzten,
								POIs (Ort von Interesse) usw.
							</li>
							<li>Verwalten Sie die Kilometerstände aller im Einsatz befindlichen Fahrzeuge</li>
							<li>Berechnen und kontrollieren Sie monatliche Kilometerstände und -spesen</li>
						</ul>
					</div>
				</FadeUp>

				<FadeUp className="card card-wide purple">
					<icon className="material-icons">group</icon>

					<div className="card-wide-head">
						<img src={Resources} alt="Ressourcenverwaltung" />
					</div>

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">group</icon>Ressourcenverwaltung
						</h2>

						<p>
							<b>
								PflegeSuite bietet umfassende Hilfsmittel zur Strukturierung und Verwaltung Ihres Personals und anderer
								wichtiger Ressourcen.
							</b>
						</p>

						<br />

						<ul>
							<li>
								Erstellen Sie Teams neben den automatisch generierten, um die Arbeitslast besser zu verwalten und zu
								organisieren
							</li>
							<li>Überblick und Verwaltung von Dienstfahrzeugen und privaten Fahrzeugen von Mitarbeitern</li>
							<li>
								Die zum Adressbuch hinzugefügten Elemente können für spätere Planung problemlos wiederverwendet werden
							</li>
							<li>Management von aktiven, abwesenden und inaktiven Mitarbeitern</li>
							<li>
								Das Kompetenzensystem für Mitarbeiter ermöglicht eine präzisere Verwaltung und verringert menschliche
								Fehler
							</li>
							<li>Lohnabrechnungssystem für Mitarbeiter zur Verwendung in Dienstplan- und Tourenplan-Berechnungen</li>
							<li>Passen Sie die Transportmethoden und Schichteinstellungen der Mitarbeiter an</li>
							<li>Benachrichtigungssystem für Kundenverordnungen, bevorstehende Geburtstage, Nachrichten usw.</li>
						</ul>
					</div>
				</FadeUp>
			</div>
		</main>
	);
};

export default LandingFunctions;
