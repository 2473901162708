import React, { useState } from "react";
import { useTransition, animated } from "react-spring";

const Modal = props => {
	const [isOpen, setOpen] = useState(false);
	const transition = useTransition(isOpen, null, {
		from: { opacity: 0, transform: "scale(1.2)" },
		enter: { opacity: 1, transform: "scale(1)" },
		leave: { opacity: 0, transform: "scale(1.2)" },
		config: { tension: 300, friction: 25 }
	});
	const parent = props;

	return (
		<>
			<button onClick={() => setOpen(true)} name="email-submit" type="submit">
				<icon className="material-icons icon">{parent.buttonIcon}</icon>
				{parent.buttonText}
			</button>
			{transition.map(
				({ item, key, props }) =>
					item && (
						<div className="overlay">
							<animated.div className="dialog blue" key={key} style={props}>
								<div className="dialog-header">
									<icon className="material-icons icon-dlg">{parent.headerIcon}</icon>
									<span>{parent.headerText}</span>
								</div>
								<div className="dialog-content confirm">{parent.children}</div>
							</animated.div>
						</div>
					)
			)}
		</>
	);
};

export default Modal;
