import React from "react";

import "../document.css";

import { ReactComponent as Logo } from "../img/logo-inline.svg";
import PinnacleLogo from "../img/pinnacle-logo.gif";

const Contract = () => {
	return (
		<main>
			<div class="card-text contract">
				<ps-header>
					<Logo title="PflegeSuite" />

					<h2>Nutzungsvertrag der Webapplikation PflegeSuite</h2>

					<img src={PinnacleLogo} alt="PinnacleSoft" />
				</ps-header>

				<br />
				<br />

				<tmp-row>
					<tmp-column>
						<tmp-h3>Daten Kunde:</tmp-h3>

						<tmp-field class="label-top">
							<label>Firmenname</label>
							<div />
						</tmp-field>

						<tmp-row>
							<tmp-field class="label-top">
								<label>IK #</label>
								<div />
							</tmp-field>

							<tmp-field class="label-top">
								<label>Tel #</label>
								<div />
							</tmp-field>

							<tmp-field class="label-top">
								<label>Fax #</label>
								<div />
							</tmp-field>
						</tmp-row>

						<tmp-field class="label-top">
							<label>Adresse</label>
							<div />
						</tmp-field>
					</tmp-column>

					<tmp-column>
						<tmp-h3>Daten Pinnacle Soft:</tmp-h3>

						<tmp-field class="label-top">
							<label>Firmenname</label>
							<div>Pinnacle Soft</div>
						</tmp-field>

						<tmp-row>
							<tmp-field class="label-top">
								<label>Tel #</label>
								<div>+49 163 7726 773</div>
							</tmp-field>

							<tmp-field class="label-top">
								<label>Fax #</label>
								<div />
							</tmp-field>
						</tmp-row>

						<tmp-field class="label-top">
							<label>Adresse</label>
							<div>Langelandweg 6 24109 Kiel</div>
						</tmp-field>
					</tmp-column>
				</tmp-row>

				<h3>§1 Vertragsgegenstand</h3>

				<p>
					Gegenstand des Vertrags ist die Nutzung der Webapplikation PflegeSuite unter www.pflegesuite.de. Die
					Webapplikation ermöglicht dem Kunden die Verwaltung und Organisation eines Pflegedienstes. Der Kunde und die
					von ihm bestimmten Mitarbeiter erhalten hierzu Zugang zur Webapplikation.
				</p>

				<p>
					Der Umfang der zur Verfügung gestellten Dienste ist unter http://www.pflegesuite.de/landing-functions.html
					definiert. Pinnacle Soft behält sich vor, die Inhalte zu aktualisieren oder gegen andere, gleichwertige
					Inhalte auszutauschen.
				</p>

				<p>
					Pinnacle Soft gewährt dem Kunden ein eingeschränktes, vertraglich festgelegtes, nicht ausschließliches und
					nicht auf Dritte übertragbares Recht, das Produkt in der Bundesrepublik Deutschland entsprechend den
					Regelungen in §3 zu nutzen.
				</p>

				<p>
					Der beauftragte Host ist die Firma Contabo GmbH, Aschauer Straße 32a, 81549 München (www.contabo.de). Dort
					werden die vom Kunden eingegebenen Daten gespeichert. Diese garantiert eine Verfügbarkeit des Netzwerkes von
					fast 100% im Jahresmittel. Hiervon ausgenommen sind Zeiten, in denen die Erreichbarkeit aufgrund von
					technischen oder sonstigen Problemen, die nicht im Einflussbereich des Providers liegen, auftreten. Die Firma
					beachtet die aktuellen Datenschutzvorschriften. Die Daten werden dort im Rahmen einer Datensicherung in
					regelmäßigem Turnus gesichert und physikalisch getrennt aufbewahrt.
				</p>

				<p>
					Soweit Wartungsarbeiten am System durchgeführt werden, wird der Kunde rechtzeitig vorher per E-Mail über den
					voraussichtlichen Beginn und das voraussichtliche Ende dieser Wartungsarbeiten informiert werden. Während des
					Zeitraums der Wartungsarbeiten ist die Webapplikation nicht erreichbar.
				</p>

				<p>Ein Anspruch auf Abschluss eines Nutzungsvertrags besteht nicht.</p>

				<p>
					Pinnacle Soft weist ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation
					per E-Mail) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff durch Dritte geschützt werden
					kann.
				</p>

				<h3>§2 Vertragsabschluss / Abrechnung</h3>

				<p>
					Der Nutzungsvertrag kommt durch Bestätigung des vorliegenden Vertrages durch Pinnacle Soft zustande. Der Kunde
					stimmt hiermit ausdrücklich der Übertragung der Daten und Speicherung am Ort der Niederlassung der Firma
					Contabo GmbH zu.
				</p>

				<p>
					Gewünschte Änderungen des Vertrages können jederzeit durch Pinnacle Soft dem Kunden gegenüber angeboten
					werden. Dieser hat Gelegenheit, innerhalb einer Frist von vier Wochen ab Zugang der Änderung zu widersprechen.
					Für die Einhaltung der Frist ist der Zugang bei Pinnacle Soft entscheidend. Widerspricht der Kunde nicht
					fristgemäß, wird der Vertrag mit den neuen Bedingungen fortgesetzt.
				</p>

				<p>
					Der Vertragsabschluss erfolgt ausschließlich mit gewerbsmäßig mit der Alten-, und Krankenpflege tätigen
					Unternehmen.
				</p>

				<p>
					Pinnacle Soft kann die Nutzung der Webapplikation oder einzelner Funktionen oder den Umfang, in dem einzelne
					Funktionen und Services genutzt werden können, an bestimmte Voraussetzungen knüpfen, wie z.B. Prüfung der
					Anmeldedaten, Zahlung des monatlichen Entgeltes, Wirtschaftsauskunft.
				</p>

				<p>
					Der Kunde erhält den Login und Passwort per E-Mail an die angegebene E-Mailadresse und wird gleichzeitig auf
					der Webapplikation freigeschaltet.
				</p>

				<h3>§3 Pflichten des Kunden / Zahlungsweise / Nutzung</h3>

				<p>
					Die Gebühren sind monatlich jeweils zum 1. des Folgemonats zur Zahlung fällig. Für die Rechtzeitigkeit der
					Zahlung ist die endgültige Gutschrift bei Pinnacle Soft entscheidend.
				</p>

				<p>
					Der Kunde kann die Rechtzeitigkeit der Zahlung durch Erteilung einer Einzugsermächtigung oder Einrichten eines
					Dauerauftrages sicherstellen. Zahlungen sind ausschließlich auf das Konto der Pinnacle Soft zu erbringen.
				</p>

				<p>
					Der Kunde wird vor vollumfänglicher Nutzung der Webapplikation sicherstellen, dass die Applikation mit seinem
					System und seiner technischen Umgebung funktionsfähig ist und keine Fehler (z.B.
					Kompatibilitätsschwierigkeiten) auftreten. Der Kunde wird das Produkt ggf. zur Vermeidung von Ausfällen auf
					einem Testsystem probehalber testen. Erst nach Feststellung der Fehlerfreiheit auch unter realitätsnahen
					Bedingungen (u.a. unter zumindest üblicher und erwarteter Volllast), wird der Kunde die Nutzung in seiner
					technischen Umgebung aufnehmen.
				</p>

				<p>
					Die Geschwindigkeit der Datenübertragung ist wesentlich von der Verbindungsleitung des Kunden zum Internet
					abhängig. Beachten Sie hierzu bitte §8 "Einsatzbedingungen".
				</p>

				<p>
					Datenbank-, Applikationserver- und von anderen Unternehmen erstellte und angebotene Lizenzen gehören nicht zum
					Liefer- und Leistungsumfang dieses Vertrages.
				</p>

				<p>
					Der Kunde selbst ist verpflichtet, die datenschutzrechtlichen Bestimmungen hinsichtlich des Zugriffs seiner
					Mitarbeiter oder Dritter zu beachten. Pinnacle Soft ist weder zur Beratung noch zur Überwachung der Einhaltung
					der rechtlichen Bestimmungen verpflichtet.
				</p>

				<h3>§4 Nutzungsumfang</h3>

				<p>Der Nutzungsumfang bestimmt sich durch die dem Kunden vorliegende Preisliste.</p>

				<p>
					Der Kunde erhält die Möglichkeit, sein Login (zwingend: E-Mailadresse) und Passwort (6+ Stellen) selbst zu
					bestimmen. Der Kunde wird selbst durch entsprechende Vorkehrungen dafür Sorge tragen, dass seine Zugangsdaten
					geheim bleiben. Soweit er Anhaltspunkte für unrechtmäßigen Zugang erhält, wird er Pinnacle Soft unverzüglich
					hierüber unterrichten.
				</p>

				<p>
					Der Kunde darf die Ergebnisse der Nutzung der Webapplikation, insbesondere etwaige Ausdrucke zu ausschließlich
					eigenen Zwecken vervielfältigen, soweit dies für die Benutzung der Webapplikation und für sein Unternehmen
					notwendig ist. Eine Zurverfügungstellung der Nutzungen an Dritte bzw. Weitergabe zur gewerblichen Nutzung an
					Dritte, auch wenn insoweit Personenidentität besteht, ist nicht zulässig.
				</p>

				<p>
					Zu den erlaubten Vervielfältigungen zählen das Anzeigen der Webapplikation auf der vom Kunden zu bestimmenden
					Datenverarbeitungseinheit, das Fertigen von Ausdrucken und Kopien im vertragsgemäßen Umfang.
				</p>

				<p>
					Der Kunde ist berechtigt, das überlassene Produkt mit anderen Programmen zu verbinden, soweit die
					Webapplikation entsprechende Schnittstellen hierfür aufweist. Der Kunde wird selbst die Kompatibilität der
					Programme sicherstellen (siehe entsprechend §3).
				</p>

				<p>
					Der Kunde ist nicht berechtigt, die ihm eingeräumten Nutzungsrechte vollständig oder teilweise auf, Dritte zu
					übertragen oder diesen Dritten entsprechende Nutzungsrechte einzuräumen.
				</p>

				<h3>§5 Schutz der Urheber / Lizenzrechte</h3>

				<p>
					Vorbehaltlich der in diesem Vertrag eingeräumten Nutzungsrechte behält Pinnacle Soft alle Rechte an der
					Webapplikation, deren Funktionen und Gestaltung sowie an allen Kopien oder Teilkopien (insbesondere Ausdrucke)
					in der an die Berechtigten überlassenen, abgeänderten oder bearbeiteten Fassung, unbeschadet des Eigentums des
					Kunden am jeweiligen Medium.
				</p>

				<p>
					Der Kunde verpflichtet sich, die vorhandenen Schutzvermerke, wie Copyright-Vermerke und andere
					Rechtsvorbehalte, in den zur Verfügung gestellten Fassungen unverändert beizubehalten und in alle vom Kunden
					vertragsgemäßen hergestellten Kopien oder Teilkopien (insbesondere Ausdrucke) zu übernehmen.
				</p>

				<p>
					Der Kunde verpflichtet sich, überlassene, abgeänderte oder bearbeitete Fassungen (insbesondere Kopien und
					Ausdrucke) ohne ausdrückliche schriftliche Zustimmung der Pinnacle Soft Dritten nicht zugänglich zu machen.
					Dies gilt auch für den Fall einer vollständigen oder teilweisen Veräußerung oder Auflösung des Unternehmens
					des Kunden. Als Dritte gelten nicht Arbeitnehmer oder Patienten des Kunden oder andere Personen, solange sie
					sich zur vertragsmäßigen Nutzung der Webapplikation für den Kunden bei diesem aufhalten und die Webapplikation
					nicht zu eigenen gewerblichen Zwecken gebrauchen.
				</p>

				<p>
					Änderungen und Erweiterungen des Lizenzmaterials werden ausschließlich von Pinnacle Soft vorgenommen. Das gilt
					auch für die Beseitigung von Fehlern.
				</p>

				<p>
					Der Kunde wird vor dem Verkauf oder der sonstigen, nicht in diesem Vertrag geregelten, Weitergabe von
					maschinenlesbaren Aufzeichnungsträgern, Speichern oder Datenverarbeitungsgeräten darin gespeichertes
					Lizenzmaterial vollständig und endgültig löschen. Dies gilt auch für Teilkopien und für abgeänderte oder
					bearbeitete Fassung.
				</p>

				<h3>§6 Mängel, Mangelbeseitigung</h3>

				<p>
					Die Vertragsparteien stimmen darin überein, dass es nicht möglich ist, Programme so zu entwickeln, dass sie
					für alle Anwendungsbedingungen fehlerfrei sind. Pinnacle Soft gewährleistet die Übereinstimmung des Produktes
					mit der bei Vertragsabschluss gültigen und von dem Kunden vor Vertragsabschluss eingesehenen Beschreibungen
					des Vertragsgegenstandes unter http://www.pflegesuite.de/landing-functions.html, auch unter §1
					"Vertragsgegenstand", die u.a. die bestimmungsgemäße Benutzung und die Einsatzbedingungen des Produktes
					angibt.
				</p>

				<p>
					Im Falle erheblicher Abweichungen von der Beschreibung des Vertragsgegenstandes ist Pinnacle Soft nach ihrer
					Wahl zur Nachbesserung berechtigt und, soweit diese nicht mit unangemessenem Aufwand verbunden ist, auch
					verpflichtet. Gelingt es Pinnacle Soft innerhalb einer angemessenen Pflicht nicht, durch Nachbesserungen oder
					max. drei Ersatzlieferungen die erheblichen Abweichungen von der Programmspezifikation zu beseitigen oder so
					zu umgehen, dass dem Kunden eine vertragsgemäße Nutzung des Produktes ermöglicht wird, kann der Kunde eine
					Minderung der Lizenzgebühren verlangen. Für Schadensersatzansprüche gilt §9. Kunde ist verpflichtet, bei der
					Eingrenzung von Fehlern mitzuwirken.
				</p>

				<p>
					Pinnacle Soft steht für die Mängelbeseitigung ein, wenn der Kunde Pinnacle Soft unverzüglich, spätestens
					binnen einer Woche nach Auftritt des Fehlers, schriftlich über den Auftritt des Fehlers informiert und
					nachprüfbare Unterlagen über Art und Auftreten von Abweichungen zur Verfügung stellt.
				</p>

				<p>
					Die Vertragspflicht erstreckt sich nicht auf das Beseitigen von Problemen, die durch das Abweichen von denen
					für die Webapplikation vorgesehenen Einsatzbedingungen verursacht werden sowie auf Mängel in abgeänderten oder
					bearbeiteten Fassungen der Webapplikation, soweit nicht nachgewiesen wird, dass vorhandene Mängel in keinem
					Zusammenhang mit den Änderungen oder Bearbeitungen stehen.
				</p>

				<h3>§7 Einsatzbedingungen</h3>

				<p>
					Das vorhandene Datenverarbeitungssystem muss folgende Mindestkonfiguartion aufweisen: Prozessor mit 1.0 GHz &
					512 MB RAM / Empfohlen: Prozessor mit 2.0 GHz & 1 GB RAM oder besser
				</p>

				<p>Datenübertragungsrate: mind. 384 kbit/s</p>

				<p>Zulässige Browser: Google Chrome 64.0+, Mozilla FireFox 64.0+</p>

				<p>
					Bei einer Benutzung der Webapplikation ohne Einhaltung der Einsatzbedingungen entfällt die Verpflichtung zur
					Mangelbeseitigung nach § 6 „Mängel, Mangelbeseitigung“. Pinnacle Soft wird sich in einem solchen Fall in einem
					angemessenen Umfang bemühen, den Support, gemäß § 7 „Support“ mit Verweis auf den abgeschlossenen
					Supportvertrag zu leisten.
				</p>

				<h3>§8 Haftungsbeschränkungen</h3>

				<p>
					Pinnacle Soft haftet für Schäden, die durch die vorsätzliche oder grob fahrlässige Verletzung einer
					wesentlichen Vertragspflicht in einer das Erreichen des Vertragszweckes gefährdenden Weise verursacht wurden.
				</p>

				<p>
					Bei leicht fahrlässigen Pflichtverletzungen beschränkt sich die Haftung von Pinnacle Soft auf den nach Art der
					geschuldeten Leistung nach dem gewöhnlichen Lauf der Dinge zu erwartenden Schaden. Dies umfasst daher nur den
					vorhersehbaren, vertragstypischen, unmittelbaren Durchschnittsschaden. Dies gilt auch bei leicht fahrlässigen
					Pflichtverletzungen der gesetzlichen Vertreter oder Erfüllungsgehilfen der Pinnacle Soft. Dieser wird
					vorliegend für jeden Ausfall mit 1/30 des Monatsbeitrages bestimmt, maximal 50% des Monatsbeitrages je Monat.
				</p>

				<p>
					Das Risiko der wirtschaftlichen Verwertbarkeit liegt beim Kunden. Pinnacle Soft haftet somit, im Einzelnen,
					nicht für einen evt. eintretenden mangelnden wirtschaftlichen Erfolg, entgangenen Gewinn, mittelbare Schäden,
					Mangelfolgeschäden und Ansprüche Dritter.
				</p>

				<p>
					Für den Verlust von Daten und deren Wiederherstellung haftet Pinnacle Soft nach Maßgabe von Abs. (1) bis (3)
					nur dann, wenn ein solcher Verlust durch angemessene Datensicherungsmaßnahmen seitens des Kunden nicht
					vermeidbar gewesen wäre.
				</p>

				<p>
					Können durch Einwirkung höherer Gewalt, z.B. Krieg oder Unruhe, Naturkatastrophen oder Feuer, Epidemien oder
					Quarantäne, Streik oder Aussperrungen, Maßnahme der Regierung oder ähnliche Umstände, vertragliche
					Verpflichtungen nicht, nicht rechtzeitig oder sonst nicht vertragsgemäß erfüllt werden, so ist die betreffende
					Vertragspartei im Umfang der Einwirkung von der Einhaltung dieser Verpflichtung befreit. Die Parteien werden
					sich über Fälle höherer Gewalt unverzüglich unterrichten.
				</p>

				<h3>§9 Datenschutz</h3>

				<p>
					Pinnacle Soft und ihre Vertragspartner beachten die datenschutzrechtlichen Vorschriften. Personenbezogene
					Daten werden ausschließlich zur Durchführung dieses Vertrages erhoben, gespeichert und verwendet.
				</p>

				<p>
					Es ist Aufgabe des Kunden über die durch die Softwaregestaltung durchzuführenden Zugriffsbeschränkungen seiner
					Nutzer darauf zu achten, dass er nicht gegen gültige Datenschutzvorschriften verstößt.
				</p>

				<p>
					Pinnacle Soft erhebt und speichert automatisch in ihren Server Log-Files-Informationen, die der Browser des
					Kunden übermittelt. Dies sind: Browsertyp/ -version, verwendetes Betriebssystem, Referrer URL (die zuvor
					besuchte Seite), Hostname des zugreifenden Rechners (IP Adresse), Uhrzeit der Serveranfrage. Diese Daten sind
					für Pinnacle Soft bestimmten Personen nicht zuordenbar. Eine Zusammenführung dieser Daten mit anderen
					Datenquellen wird nicht vorgenommen, die Daten werden zudem nach einer statistischen Auswertung gelöscht.
				</p>

				<p>Der Kunde kann jederzeit Auskunft über die von ihm gespeicherten Daten erhalten.</p>

				<h3>§10 Schutzrechte Dritter</h3>

				<p>Pinnacle Soft erklärt, ihres Wissens nach, keine gewerblichen Schutzrechte Dritter verletzt zu haben.</p>

				<p>
					Für den Fall, dass entsprechende Ansprüche gegen den Kunden allein aufgrund des Produktes geltend gemacht
					werden, stellt Pinnacle Soft den Kunden von diesen Ansprüchen frei. Voraussetzung hierfür ist, dass der Kunde
					Pinnacle Soft über das Geltendmachen dieser Ansprüche und Rechte unverzüglich informiert und ihn weitgehend
					bei der Verteidigung unterstützt.
				</p>

				<h3>§11 Kündigung</h3>

				<p>
					Mit Wirksamwerden der Kündigung wird der Zugang zu Webapplikation für alle Nutzer des Kunden mit Ablauf des
					letzten Tages der Kündigungsfrist (24.00 Uhr) gesperrt werden.
				</p>

				<p>
					Der Kunde kann diesen Vertrag jeweils ordentlich mit einer Frist von 1 Monat schriftlich kündigen. Mit
					Wirksamwerden der Kündigung des Kunden erlischt dessen Recht zur weiteren Nutzung.
				</p>

				<p>Entscheidend für die Rechtzeitigkeit der Kündigung ist der Zugang beim Kündigungsempfänger.</p>

				<p>
					Der Vertrag kann von beiden Vertragspartnern außerordentlich aus wichtigem Grund fristlos gekündigt werden.
					Ein wichtiger Grund ist insbesondere auch dann anzunehmen, wenn der Kunde mit dem Nutzungsentgelt für die
					Webapplikation mit mehr als zwei Beiträgen in Verzug ist, er eine vertragswidrige Nutzung trotz Aufforderung
					durch Pinnacle Soft fortsetzt bzw. wiederholt, das Insolvenzverfahren über das Vermögen des Kunden eröffnet
					oder mangels Masse abgewiesen wurde oder sonstige begründete Bedenken an der Zahlungsfähigkeit des Kunden
					bestehen. Die Kündigung wird mit Zugang beim Empfänger wirksam.
				</p>

				<p>
					Innerhalb von 30 Tagen nach Wirksamwerden der Kündigung werden alle vom Kunden eingetragenen Daten endgültig
					gelöscht werden. Auf besondere Anfrage des Kunden können die Daten in Dateiform übergeben werden. Die hierfür
					entstehenden Kosten hat der Kunde zu tragen. Pinnacle Soft wird ihm hierzu aufgrund der Anfrage eine
					Kostenkalkulation übersenden und hierbei das Dateiformat benennen.
				</p>

				<h3>§12 Verjährung</h3>

				<p>
					Ansprüche aufgrund einer Verletzung von Bestimmungen nach §3 „Pflichten des Kunden / Nutzung“, §4
					„Nutzungsumfang“ und §5 „Schutz der Urheber / Lizenzrechte“ verjähren drei Jahre nach ihrer Entstehung und der
					Kenntnis hiervon beim Pinnacle Soft. Alle anderen Ansprüche aus diesem Vertrag verjähren 3 Jahre nach Ihrer
					Entstehung, sofern nicht kürzere gesetzliche oder vertraglich vereinbarte Verjährungsfristen (z.B. in §6)
					gelten.
				</p>

				<h3>§13 Schlussbestimmungen</h3>

				<p>
					Der Kunde ist nicht zur Aufrechnung oder zum Zurückbehalt berechtigt, so lange die Ansprüche nicht von
					Pinnacle Soft ausdrücklich anerkannt oder gerichtlich festgestellt wurden. Zu einer Abtretung seiner Rechte
					aus diesem Vertrag bedarf der Kunde der schriftlichen Einwilligung des Pinnacle Soft.
				</p>

				<p>
					Der Vertrag enthält alle zwischen den Vertragsparteien getroffenen Vereinbarungen. Änderungen und Ergänzungen
					bedürfen der Schriftform.
				</p>

				<p>
					Der Erfüllungsort und der Gerichtsstand für sämtliche Streitigkeiten, die u.a. im Rahmen der Abwicklung dieses
					Vertragsverhältnisses entstehen, ist der Sitz der Pinnacle Soft.
				</p>

				<p>
					Die Parteien vereinbaren hinsichtlich sämtlicher Rechtsbeziehungen aus diesem Vertragsverhältnis die Anwendung
					des Rechts der Bundesrepublik Deutschland.
				</p>

				<p>
					Die Rechtsunwirksamkeit einer hier aufgeführten Bestimmung berührt in keinster Weise die Rechtswirksamkeit der
					anderen Vertragsbestandteile. Die Vertragsparteien verpflichten sich, eine unwirksame Bestimmung durch eine
					wirksame Regelung zu ersetzen, die dem Vertragszweck am Nächsten kommt. Der Kunde stimmt bereits jetzt zu,
					dass der Pinnacle Soft diese nach §315 BGB bestimmt. Gleiches gilt für etwaige Regelungslücken.
				</p>

				<br />
				<br />
				<br />
				<br />

				<tmp-row>
					<tmp-column>
						<tmp-row>
							<tmp-field class="label-bottom">
								<label>Datum, Ort</label>
								<div />
							</tmp-field>

							<tmp-field class="label-bottom">
								<label>Unterschrift Kunde</label>
								<div />
							</tmp-field>
						</tmp-row>
					</tmp-column>

					<tmp-column>
						<tmp-row>
							<tmp-field class="label-bottom">
								<label>Datum, Ort</label>
								<div />
							</tmp-field>

							<tmp-field class="label-bottom">
								<label>Unterschrift Pinnacle Soft</label>
								<div />
							</tmp-field>
						</tmp-row>
					</tmp-column>
				</tmp-row>
			</div>

			<br />
			<br />
		</main>
	);
};

export default Contract;
