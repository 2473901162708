import React from "react";

const Error = () => {
	return (
		<main>
			<h1>Keine Seite gefunden</h1>
		</main>
	);
};

export default Error;
