import React from "react";
import { NavLink } from "react-router-dom";
import zadTechlogo from "../img/zadtech_logo_white.png";

const Footer = () => {
	return (
		<footer>
			<div className="footer-content">

				<div className="footer-text">
					<NavLink to="gdpr">Datenschutzerklärung</NavLink>
					<NavLink to="impressum">Impressum</NavLink>
					<NavLink to="timeline">Timeline</NavLink>
				</div>

				<div className="footer-text">
					<p>
						<b>PflegeSuite - 2018</b>
					</p>
					<a href="http://www.pflegesuite.de">www.pflegesuite.de</a>
					<br />
					<p>
						<b>Kontakt:</b>
					</p>
					<a href="mailto:team@pflegesuite.de">team@pflegesuite.de</a>
					<p>+49 5551 9688-0</p>
				</div>
				
				<div className="footer-text">
					<p>
						<img className="logo-zadtech" src={zadTechlogo} alt="ZAD Tech Logo" />
					</p>
				</div>

			</div>
		</footer>
	);
};

export default Footer;
