import React from "react";

import Modal from "./modal";
import Email from "./email";
import FadeUp from "./fade-up";

import Slider from "../img/slider-img.jpg";
import Display from "../img/screenshots/Monitor-DP.png";
import Tablet from "../img/screenshots/iPad-Harry.png";
import Phone from "../img/screenshots/GalaxyS6-Fahrtenbuch.png";
import Laptop from "../img/screenshots/Macbook-TPmap.png";

const MainContent = () => {
	return (
		<main>
			<h1>Übersicht</h1>
			<div className="container">
				<div className="card card-wide card-main">
					<div className="card-wide-head">
						<div className="bricks">
							<div className="brick" />
							<div className="brick br-top" />
							<div className="brick" />
							<div className="brick br-left" />
							<div className="brick br-mid" />
							<div className="brick br-right" />
							<div className="brick" />
							<div className="brick br-bot" />
							<div className="brick" />
						</div>
					</div>

					<div className="card-text">
						<h2>Das neue System für Ihren ambulanten Pflegedienst</h2>

						<p>
							PflegeSuite wurde speziell dafür entwickelt, den derzeitig unvollkommenen Systemen auf dem Markt eine
							optimale Lösung entgegenzustellen. In Zusammenarbeit mit unseren Partnern im Pflegebereich sind wir darauf
							fokussiert, Ihnen alle Hilfsmittel zur Verfügung zu stellen, die Sie für Ihre tägliche Arbeit benötigen.
						</p>

						<p>
							Unser System bietet Ihnen Benutzerfreundlichkeit, Übersichtlichkeit und Einheitlichkeit Ihrer Daten. So
							können Sie Ihre Informationen sehr einfach verwalten und anpassen sowie wichtige Dokumente wie
							Leistungsnachweise und Fahrtenbücher erstellen.
						</p>

						<br />

						<span className="blue">
							<Modal
								buttonText="Jetzt ausprobieren!"
								buttonIcon="done_outline"
								headerText="PflegeSuite unverbindlich ausprobieren"
								headerIcon="gamepad">
								<Email />
							</Modal>
						</span>

						<img src={Slider} alt="" />
					</div>
				</div>

				<FadeUp className="card-wide card-ss teal">
					<div>
						<div className="ss ss-dp">
							<img src={Display} alt="" />
							<div>
								<h4>Übersichtlich</h4>
								<p>Behalten Sie einen klaren Überblick über Ihre Daten, Ressourcen und Zeitverwaltung.</p>
								<p>Eine Intelligente Farbcodierung sorgt für leichte Orientierung und Erkennung wichtiger Elemente.</p>
							</div>
						</div>

						<div className="ss ss-tablet">
							<div>
								<h4>Einfach</h4>
								<p>Für Menschen entwickelt.</p>
							</div>
							<img src={Tablet} alt="" />
						</div>
					</div>

					<div>
						<div className="ss ss-phone">
							<img src={Phone} alt="" />
							<div>
								<h4>Flexibel</h4>
								<p>Hinzufügen, entfernen, ändern - alles geht per Klick.</p>
								<p>So können Sie z.B. in wenigen Minuten benutzerdefinierte Routen fürs Fahrtenbuch erstellen.</p>
							</div>
						</div>

						<div className="ss ss-tp">
							<div>
								<h4>Modern</h4>
								<p>Wenn Sie mit einem Smartphone umgehen können, kommen Sie auch mit PflegeSuite zurecht.</p>
							</div>
							<img className="ss-tp" src={Laptop} alt="" />
						</div>
					</div>
				</FadeUp>

				<FadeUp className="card teal">
					<div className="card-header" />

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">add_to_queue</icon>Modern und übersichtlich
						</h2>

						<p>
							Ihr Verwaltungssystem sollte übersichtlich, strukturiert und optisch ansprechend sein, schließlich
							verbringen Sie den größten Teil Ihrer Arbeitszeit damit.
						</p>
					</div>
				</FadeUp>

				<FadeUp className="card purple">
					<div className="card-header" />

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">directions</icon>Automatisierte Fahrtenbücher
						</h2>

						<p>
							In PflegeSuite lassen sich viele sehr wichtige Datensätze wie Fahrtenbücher oder Leistungsnachweise mit
							einem Klick erstellen.
						</p>
					</div>
				</FadeUp>

				<FadeUp className="card green">
					<div className="card-header" />

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">playlist_add_check</icon>Objektorientiert, konsequent
						</h2>

						<p>
							Jeder Beitrag stellt keinen einzelnstehenden Eintrag dar, sondern ein komplexes Modell einer realen
							Situation, das aus zusammenhängenden Daten besteht.
						</p>
					</div>
				</FadeUp>

				<FadeUp className="card red">
					<div className="card-header" />

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">security</icon>Datensicherheit und Support
						</h2>

						<p>
							Ihre Daten werden durch regelmäßige Backups auf unseren sicheren https-Servern gesichert. Unser
							Kundendienst steht Ihnen täglich kostenlos zur Verfügung.
						</p>
					</div>
				</FadeUp>

				<FadeUp className="card blue">
					<div className="card-header" />

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">cloud_done</icon>Immer aktuell in der Cloud
						</h2>

						<p>
							Ihr Kundenkonto wird automatisch und kostenlos in unserer Cloud immer auf dem neusten Stand gehalten. Wir
							führen ständig neue Funktionen gemäß Ihren Wünschen und Anregungen ein.
						</p>
					</div>
				</FadeUp>

				<FadeUp className="card gold">
					<div className="card-header" />

					<div className="card-text">
						<h2>
							<icon className="material-icons icon">euro_symbol</icon>Kosteneffizient, transparent
						</h2>

						<p>
							Keine versteckten Kosten, keine komplizierten Zahlungsmodalitäten, keine obligatorischen Schulungen. Mit
							dem klaren und einfachen Zahlungsplan von PflegeSuite sparen Sie Geld - unabhängig von der Größe Ihres
							Unternehmens.
						</p>
					</div>
				</FadeUp>
			</div>

			<FadeUp className="quotes">
				<p className="quote">
					<icon className="material-icons">format_quote</icon>
					<i>Das einfachste und übersichtlichste System, das wir je gesehen haben.</i>
					<b>MDK Prüfung</b>
				</p>

				<p className="quote">
					<icon className="material-icons">format_quote</icon>
					<i>
						Ich kann mit den Routen-, und Dienstplänen anderer Systeme einfach nicht umgehen. Aber mit Ihrem System habe
						ich endlich alles im Griff.
					</i>
					<b>Harmonie Ambulanter Pflegedienst</b>
				</p>

				<p className="quote">
					<icon className="material-icons">format_quote</icon>
					<i>Mit PflegeSuite haben wir uns sehr viel Arbeitszeit und Geld gespart.</i>
					<b>Ambulanter Pflegedienst Nordstern GmbH</b>
				</p>
			</FadeUp>
		</main>
	);
};

export default MainContent;
