import React from "react";
import { useInView } from "react-intersection-observer";

const FadeUp = props => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.25
	});

	return (
		<div style={{ opacity: 0 }} className={`${props.className} ${inView ? "fade-up" : ""}`} ref={ref}>
			{props.children}
		</div>
	);
};

export default FadeUp;
