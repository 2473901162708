import React from "react";

const LandingTutorials = () => {
	return (
		<main>
			<h1>Anleitungen und Lernvideos</h1>

			<div className="container">
				<div className="card teal">
					<iframe
						title="Lernvideo"
						className="card-video"
						src="https://www.youtube.com/embed/NgHGZzriQ-w?rel=0"
						frameBorder="0"
						allow="autoplay; encrypted-media"
						allowFullScreen
					/>

					<div className="card-text">
						<h2>Lernvideo</h2>

						<p>Dieser bereich befindet sich im Aufbau, und wird in Kürze verfügbar sein.</p>
					</div>
				</div>

				<div />
				<div />
			</div>
		</main>
	);
};

export default LandingTutorials;
