import React from "react";

import zadTechLogo from "../img/zadtech_logo_black.png"

const LandingImpressum = () => {
	return (
		<main>
			<h1>Impressum</h1>

			<div className="container green">
				<div className="card card-wide impressum">
					<icon className="material-icons">info</icon>

					<div className="card-text">
                        <div>
                            <p>
                                <img src={zadTechLogo} alt="ZADTech Logo" />
                            </p>

                        </div>

                        <div>
                            <h3>Firmeninfo:</h3>

                            <p>
                                <b>ZAD Tech GmbH</b>
                                <br/>2022 Amtsgericht Göttingen
                                <br/>Registernummer: HRB 206788
                            </p>
                            <p>
                                <b>Geschäftsführung:</b>
                                <br/>Irina Niesen
                            </p>
                            <p>
                                <b>Adresse:</b>
                                <br/>Ottilienstraße 10 37154 Northeim
                            </p>
                            <p>
                                <b>Umsatzsteuer ID Nr.:</b>
                                <br/>DE353650360
                            </p>
                        </div>

                        <div>
                            <h3>Kontaktinformationen:</h3>

                            <p>
                                <b>Tel.:</b> +49 5551 9688-0
                                <br/>
                                <b>Email:</b> <a href="mailto:team@pflegesuite.de">team@pflegesuite.de</a>
                            </p>
                            <p>
                                <b>Inhaltlich verantwortlich:</b>
                                <br/>Sergey Potapov
                                <br/>Anschrift wie oben
                            </p>
                        </div>

					</div>
				</div>
			</div>
		</main>
	);
};

export default LandingImpressum;
