import React, { useState } from "react";

const useStateWithLocalStorage = (localStorageKey, defValue)  => {
    const [value, setValue] = useState(
        localStorage.getItem(localStorageKey) || defValue
    );
    React.useEffect(() => {
        localStorage.setItem(localStorageKey, value);
    }, [localStorageKey, value]);
    return [value, setValue];
};

export default useStateWithLocalStorage;